import React from 'react';
import './App.css';
import { store } from "./store";
import {
  ForgotPasswordComponent,
  LandingPageComponent,
  LoginComponent,
  ResetPasswordComponent,
  SetupPasswordComponent
} from "./routes";
import Layout from "./containers/Layout";
import PrivateRoute from "./containers/PrivateRoute/";
import { Provider } from "react-redux";
import { Route, Router, Switch } from 'react-router-dom';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import { history } from "./utils/utilities"
import Box from "@material-ui/core/Box";
import Loader from "./containers/Loader/Loader";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import axios from "axios";
// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function App() {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.headers.common["tc-domain"] = `dev`;
  }
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#3399cc'
      },
      secondary: {
        main: '#99cc33',
        contrastText: '#ffffff'
      }
    }
  });
  theme.typography.h2 = {
    fontSize: '1.1em',
    marginTop: '10px',
    marginBottom: '10px',
    '@media (min-width:800px)': {
      fontSize: '1.6em',
      marginTop: '20px',
      marginBottom: '20px'
    },
  }

  return (
    <Box style={{width: '100vw', height: '100vh', position: 'relative'}}>
      <Elements stripe={stripePromise}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}>
            <SnackbarUtilsConfigurator/>
            <Provider store={store}>
              <Router history={history}>
                {/*<React.StrictMode>*/}
                <Switch>
                  <Route path="/login" component={LoginComponent}/>
                  <Route path="/forgot-password" component={ForgotPasswordComponent}/>
                  <Route path="/reset-password/:token" component={ResetPasswordComponent}/>
                  <Route path="/setup-password/:token" component={SetupPasswordComponent}/>
                  <PrivateRoute path="/(home|organizations|dids|atas|cisco-atas|pbxs)" component={Layout}/>
                  <Route path="/" component={LandingPageComponent}/>
                  {/*<Redirect to="/login" exact />*/}
                </Switch>
                {/*</React.StrictMode>*/}
              </Router>
              <Loader/>
            </Provider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </Elements>
    </Box>
  );
}

library.add(fas);

export default App;

App.whyDidYouRender = true
