import loadable from '@loadable/component'

/**
 * Path routes
 */

// Home Menu
export const getHomePath = `/home`;
export const getDashboardPath = `${getHomePath}/dashboard`;
export const getBillingPath = `${getHomePath}/billings`;
export const getBillingListPath = `${getHomePath}/billings/billings`;
export const getPaymentMethodsListPath = `${getBillingPath}/payment-methods`;
// export const getOverviewPath = `${getBillingPath}/overview`;
export const getPaymentHistoryPath = `${getBillingPath}/payment-history`;

// Organizations Menu
export const getOrganizationsPath = `/organizations`;
export const getManageOrganizationsListPath = `${getOrganizationsPath}/organizations`;
export const getManageOrganizationDetailsPath = `${getManageOrganizationsListPath}/:id`;
export const getAPICredentialsListPath = `${getOrganizationsPath}/api-credentials`;
export const getAPICredentialDetailsPath = `${getAPICredentialsListPath}/:id`;

// Users Menu
export const getManageUsersListPath = `${getOrganizationsPath}/users`;
export const getManageUserDetailsPath = `${getManageUsersListPath}/:id`;

// DIDs Menu
export const getDIDsPath = `/dids`;
export const getDIDsListPath = `${getDIDsPath}/dids`;
export const getDIDDetailsPath = `${getDIDsListPath}/:id`;
export const getDIDBulkDetailsPath = `${getDIDsListPath}/bulk`;

// ATAs Menu
export const getATAsPath = `/atas`;
export const getATATypesListPath = `${getATAsPath}/ata-types`;
export const getATATypeDetailsPath = `${getATATypesListPath}/:id`;
export const getATABaseTemplatesListPath = `${getATAsPath}/ata-base-templates`;
export const getATABaseTemplateDetailsPath = `${getATABaseTemplatesListPath}/:id`;
export const getATAOrgTemplatesListPath = `${getATAsPath}/ata-org-templates`;
export const getATAOrgTemplateDetailsPath = `${getATAOrgTemplatesListPath}/:id`;
export const getATAPortsListPath = `${getATAsPath}/ata-ports`;
export const getATAPortDetailsPath = `${getATAPortsListPath}/:id`;
export const getATAsListPath = `${getATAsPath}/atas`;
export const getATADetailsPath = `${getATAsListPath}/:id`;
export const getATABulkDetailsPath = `${getATAsListPath}/bulk`;

// Cisco
export const getCiscoATAsListPath = `${getATAsPath}/cisco`;
export const getCiscoATATypeDetailsPath = `${getCiscoATAsListPath}/:id`;
export const getCiscoATAOrgTemplatesListPath = `${getATAsPath}/cisco-ata-org-templates`;
export const getCiscoATAOrgTemplateDetailsPath = `${getCiscoATAOrgTemplatesListPath}/:id`;
export const getCiscoATABaseTemplatesListPath = `${getATAsPath}/cisco-ata-base-templates`;
export const getCiscoATABaseTemplateDetailsPath = `${getCiscoATABaseTemplatesListPath}/:id`;


// PBXs Menu
export const getPBXsPath = `/pbxs`;
export const getPBXsListPath = `${getPBXsPath}/pbx`;
export const getPBXDetailsPath = `${getPBXsListPath}/:id`;


/**
 * Lazy-loaded components
 */
export const LoginComponent = loadable(() => import('./containers/Login'));
export const ForgotPasswordComponent = loadable(() => import('./containers/ForgotPassword'));
export const ResetPasswordComponent = loadable(() => import('./containers/ResetPassword'));
export const SetupPasswordComponent = loadable(() => import('./containers/SetupPassword'));

// Home Menu
export const DashboardComponent = loadable(() => import('./containers/Dashboard'));
export const BillingComponent = loadable(() => import('./containers/Billings/BillingsList'));
export const PaymentMethodListComponent = loadable(() => import('./containers/PaymentMethods/PaymentMethodsList'));
// export const OverviewComponent = loadable(() => import('./containers/Overview'));
export const PaymentHistoryComponent = loadable(() => import('./containers/PaymentHistory'));
export const LandingPageComponent = loadable(() => import('./containers/LandingPage'));

// Organization Menu
export const ManageOrganizationsComponent = loadable(() => import('./containers/Organizations/ManageOrganizations'));
export const ManageOrganizationDetailsComponent = loadable(() => import('./containers/Organizations/ManageOrganizationDetails'));

// Organization Menu
export const ManageUsersComponent = loadable(() => import('./containers/Users/ManageUsers'));
export const ManageUserDetailsComponent = loadable(() => import('./containers/Users/ManageUserDetails'));

// PBX Menu
export const DIDsComponent = loadable(() => import('./containers/DIDs/DIDsList'));
export const DIDDetailsComponent = loadable(() => import('./containers/DIDs/DIDDetails'));
export const DIDBulkDetailsComponent = loadable(() => import('./containers/DIDs/DIDBulkDetails'));
export const PBXsComponent = loadable(() => import('./containers/PBXs/PBXList'));
export const PBXDetailsComponent = loadable(() => import('./containers/PBXs/PBXDetails'));
export const ATAsComponent = loadable(() => import('./containers/ATAs/ATAsList'));
export const ATADetailsComponent = loadable(() => import('./containers/ATAs/ATADetails'));
export const ATABulkDetailsComponent = loadable(() => import('./containers/ATAs/ATABulkDetails'));

// ATA Type Menu
export const ATATypesComponent = loadable(() => import('./containers/ATATypes/ATATypesList'));
export const ATATypeDetailsComponent = loadable(() => import('./containers/ATATypes/ATATypeDetails'));
export const ATABaseTemplatesComponent = loadable(() => import('./containers/ATABaseTemplates/ATABaseTemplatesList'));
export const ATABaseTemplateDetailsComponent = loadable(() => import('./containers/ATABaseTemplates/ATABaseTemplateDetails'));
export const ATAOrgTemplatesComponent = loadable(() => import('./containers/ATAOrgTemplates/ATAOrgTemplatesList'));
export const ATAOrgTemplateDetailsComponent = loadable(() => import('./containers/ATAOrgTemplates/ATAOrgTemplateDetails'));
export const ATAPortsComponent = loadable(() => import('./containers/ATAPorts/ATAPortsList'));
export const ATAPortDetailsComponent = loadable(() => import('./containers/ATAPorts/ATAPortDetails'));

//Cisco
export const CiscoATATypesComponent = loadable(() => import('./containers/CiscoATAs/CiscoATAsList'));
export const CiscoATATypeDetailsComponent = loadable(() => import('./containers/CiscoATAs/CiscoATADetails'));
export const CiscoATAOrgTemplatesComponent = loadable(() => import('./containers/CiscoATAOrgTemplates/CiscoATAOrgTemplatesList'));
export const CiscoATAOrgTemplateDetailsComponent = loadable(() => import('./containers/CiscoATAOrgTemplates/CiscoATAOrgTemplateDetails'));
export const CiscoATABaseTemplatesComponent = loadable(() => import('./containers/CiscoATABaseTemplates/CiscoATABaseTemplatesList'));
export const CiscoATABaseTemplateDetailsComponent = loadable(() => import('./containers/CiscoATABaseTemplates/CiscoATABaseTemplateDetails'));


// API Credential Menu
export const APICredentialsComponent = loadable(() => import('./containers/APICredentials/APICredentialsList'));
export const APICredentialDetailsComponent = loadable(() => import('./containers/APICredentials/APICredentialDetails'));

// It's a flat list of URL's - cannot handle good-looking
// route nesting for now...
const routes = [
  {
    path: `${getDashboardPath}`,
    exact: true,
    name: 'Dashboard',
    component: DashboardComponent,
  },
  {
    path: `${getBillingListPath}`,
    exact: true,
    name: 'Billing',
    component: BillingComponent,
  },
  {
    path: `${getPaymentMethodsListPath}`,
    exact: true,
    name: 'Payment Methods List',
    component: PaymentMethodListComponent,
  },
  // {
  //   path: `${getOverviewPath}`,
  //   exact: true,
  //   name: 'Overview',
  //   component: OverviewComponent,
  // },
  {
    path: `${getPaymentHistoryPath}`,
    exact: true,
    name: 'Payment History List',
    component: PaymentHistoryComponent,
  },
  {
    path: `${getManageOrganizationsListPath}`,
    exact: true,
    name: 'Manage Organization List',
    component: ManageOrganizationsComponent,
  },
  {
    path: `${getManageOrganizationDetailsPath}`,
    exact: true,
    name: 'Organization Details',
    component: ManageOrganizationDetailsComponent,
  },
  {
    path: `${getManageUsersListPath}`,
    exact: true,
    name: 'Manage Users List',
    component: ManageUsersComponent,
  },
  {
    path: `${getManageUserDetailsPath}`,
    exact: true,
    name: 'User Details',
    component: ManageUserDetailsComponent,
  },
  {
    path: `${getDIDsListPath}`,
    exact: true,
    name: 'Number List',
    component: DIDsComponent,
  },
  {
    path: `${getDIDBulkDetailsPath}`,
    exact: true,
    name: 'Bulk Number Details',
    component: DIDBulkDetailsComponent,
  },
  {
    path: `${getDIDDetailsPath}`,
    exact: true,
    name: 'Number Details',
    component: DIDDetailsComponent,
  },
  {
    path: `${getPBXsListPath}`,
    exact: true,
    name: 'PBXs List',
    component: PBXsComponent,
  },
  {
    path: `${getPBXDetailsPath}`,
    exact: true,
    name: 'PBX Details',
    component: PBXDetailsComponent,
  },
  {
    path: `${getATAsListPath}`,
    exact: true,
    name: 'ATAs List',
    component: ATAsComponent,
  },
  {
    path: `${getATABulkDetailsPath}`,
    exact: true,
    name: 'Bulk ATA Details',
    component: ATABulkDetailsComponent,
  },
  {
    path: `${getATADetailsPath}`,
    exact: true,
    name: 'ATA Details',
    component: ATADetailsComponent,
  },
  {
    path: `${getATATypesListPath}`,
    exact: true,
    name: 'ATA Types List',
    component: ATATypesComponent,
  },
  {
    path: `${getATATypeDetailsPath}`,
    exact: true,
    name: 'ATA Type Details',
    component: ATATypeDetailsComponent,
  },
  {
    path: `${getATABaseTemplatesListPath}`,
    exact: true,
    name: 'ATA Base Templates List',
    component: ATABaseTemplatesComponent,
  },
  {
    path: `${getATABaseTemplateDetailsPath}`,
    exact: true,
    name: 'ATA Base Template Details',
    component: ATABaseTemplateDetailsComponent,
  },
  {
    path: `${getATAOrgTemplatesListPath}`,
    exact: true,
    name: 'ATA Org Templates List',
    component: ATAOrgTemplatesComponent,
  },
  {
    path: `${getATAOrgTemplateDetailsPath}`,
    exact: true,
    name: 'ATA Org Template Details',
    component: ATAOrgTemplateDetailsComponent,
  },
  {
    path: `${getATAPortsListPath}`,
    exact: true,
    name: 'ATA Ports List',
    component: ATAPortsComponent,
  },
  {
    path: `${getATAPortDetailsPath}`,
    exact: true,
    name: 'ATA Port Details',
    component: ATAPortDetailsComponent,
  },
  {
    path: `${getCiscoATAsListPath}`,
    exact: true,
    name: 'Cisco ATAs List',
    component: CiscoATATypesComponent,
  },
  {
    path: `${getCiscoATATypeDetailsPath}`,
    exact: true,
    name: 'Cisco ATA Details',
    component: CiscoATATypeDetailsComponent,
  },
  {
    path: `${getCiscoATAOrgTemplatesListPath}`,
    exact: true,
    name: 'Cisco ATA Org Templates List',
    component: CiscoATAOrgTemplatesComponent,
  },
  {
    path: `${getCiscoATAOrgTemplateDetailsPath}`,
    exact: true,
    name: 'Cisco ATA Org Template Details',
    component: CiscoATAOrgTemplateDetailsComponent,
  },
  {
    path: `${getCiscoATABaseTemplatesListPath}`,
    exact: true,
    name: 'Cisco ATA Base Templates List',
    component: CiscoATABaseTemplatesComponent,
  },
  {
    path: `${getCiscoATABaseTemplateDetailsPath}`,
    exact: true,
    name: 'Cisco ATA Base Template Details',
    component: CiscoATABaseTemplateDetailsComponent,
  },
  {
    path: `${getAPICredentialsListPath}`,
    exact: true,
    name: 'API Credentials List',
    component: APICredentialsComponent,
  },
  {
    path: `${getAPICredentialDetailsPath}`,
    exact: true,
    name: 'API Credential Details',
    component: APICredentialDetailsComponent,
  },
]

export default routes;
