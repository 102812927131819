import axios from 'axios';
import { history } from './utilities'
import SnackbarUtils from "./SnackbarUtils";
import * as queryString from "querystring";

export default class NetworkService {
  static setupDefaultHeaders(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  static setupInterceptors(store) {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log("interceptor error", error);
        if (+error.response?.status === 401) {
          SnackbarUtils.info(`Your session has been expired. Please log in again`);
          localStorage.removeItem('token');
          delete axios.defaults.headers.common["Authorization"];
          const query = queryString.parse(history.location.search.replace('?', ''));
          delete query['ref'];
          history.push(`/login?ref=${encodeURIComponent(`${history.location.pathname}?` + Object.keys(query || {}).map(key => key + '=' + query[key]).join('&'))}`);
        }
        return Promise.reject(error);
      }
    );
  }
}
