import axios from "axios";
import SnackbarUtils from "../../utils/SnackbarUtils";
import {
  SET_FULL_ATA_BASE_TEMPLATE_LIST,
  SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS,
  SET_FULL_ATA_LIST,
  SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST,
  SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS,
  SET_FULL_ATA_TYPE_LIST, SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST,
  SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS,
  SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST,
  SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS,
  SET_FULL_DID_LIST,
  SET_FULL_DID_LIST_FETCH_STATUS,
  SET_FULL_ORGANIZATION_LIST,
  SET_FULL_PBX_LIST,
  SET_FULL_PBX_LIST_FETCH_STATUS,
  SET_SELECTED_COMPANY
} from "./sharedData.actionTypes";
import { RequestStatus } from "../../utils/RequestStatus";
import { ROLECONSTANTS } from "../../utils/constants";

const readTree = (company, y) => {
  let ar = [];
  if (company) {
    // @ts-ignore
    ar.push({id: company.id, name: `${y} ${company.name}`});
    if (company.children) {
      company.children.forEach(x => {
        ar = [...ar, ...readTree(x, y + '\t-')];
      })

    } else return ar;
  }
  return ar;
}

export const getFullOrganizationList = (orgId?) => {
  return function (dispatch, getState) {
    let user = getState().authReducer.user;
    let isSuperAdmin = user.role === ROLECONSTANTS.superadmin;
    let params = {limit: 1000, page: 1, order: 'organization.id'};
    if (isSuperAdmin) {
      params['org_id'] = user.organization.id;
    } else {
      if(orgId === 'all') {
        params['org_id'] = user.organization.id;
      } else {
        params['org_id'] = orgId;
      }
    }
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/organizations/tree${queryString ? `?${queryString}` : ''}`

    axios
      .get(url)
      .then(response => {
        console.log("response.data.items", response.data);
        let obj = readTree(response.data, '');
        console.log("obj", obj);
        dispatch(setFullOrganizationList(obj));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full organization list!');
      });
  }
}

export function setFullOrganizationList(payload) {
  return {
    type: SET_FULL_ORGANIZATION_LIST,
    payload
  }
}

//-------------------------------------------

export const getFullATATypeList = () => {
  return function (dispatch, getState) {
    let params = {limit: 1000, page: 1, order: 'id'};
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/atas/types${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATATypeList(response.data.items));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full ATA Type list!');
      });
  }
}

export function setFullATATypeList(payload) {
  return {
    type: SET_FULL_ATA_TYPE_LIST,
    payload
  }
}

//-------------------------------------------

export const getFullATAOrganizationTemplateList = () => {
  return function (dispatch, getState) {
    let params = {limit: 1000, page: 1, order: 'ata_org_template.id'};
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/ata-org-templates${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullATAOrgTemplateListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATAOrganizationTemplateList(response.data.items));
        dispatch(setFullATAOrgTemplateListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full ATA Organization Templates list!');
        dispatch(setFullATAOrgTemplateListFetchStatus(RequestStatus.status.ERROR));
      });
  }
}

export function setFullATAOrganizationTemplateList(payload) {
  return {
    type: SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST,
    payload
  }
}

export function setFullATAOrgTemplateListFetchStatus(payload) {
  return {
    type: SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS,
    payload
  }
}


export const getFullCiscoATAOrganizationTemplateList = () => {
  return function (dispatch, getState) {
    let params = {limit: 1000, page: 1, order: 'ata_org_template.id'};
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/cisco-ata-org-templates${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullCiscoATAOrgTemplateListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullCiscoATAOrganizationTemplateList(response.data.items));
        dispatch(setFullCiscoATAOrgTemplateListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full Cisco ATA Organization Templates list!');
        dispatch(setFullCiscoATAOrgTemplateListFetchStatus(RequestStatus.status.ERROR));
      });
  }
}

export function setFullCiscoATAOrganizationTemplateList(payload) {
  return {
    type: SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST,
    payload
  }
}

export function setFullCiscoATAOrgTemplateListFetchStatus(payload) {
  return {
    type: SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS,
    payload
  }
}

//-------------------------------------------

export const getFullPBXList = (data = {}) => {
  return function (dispatch, getState) {
    dispatch(setFullPBXListFetchStatus(RequestStatus.status.FETCHING));
    let params = {limit: 1000, page: 1, order: 'pbx.id'};
    console.log("params", params);
    Object.keys(data).forEach(key => {
      params[key] = data[key]
    });
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/pbxs${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullPBXList(response.data.items));
        dispatch(setFullPBXListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full PBX list!');
        dispatch(setFullPBXListFetchStatus(RequestStatus.status.ERROR));
      });
  }
}

export function setFullPBXList(payload) {
  return {
    type: SET_FULL_PBX_LIST,
    payload
  }
}

export function setFullPBXListFetchStatus(payload) {
  return {
    type: SET_FULL_PBX_LIST_FETCH_STATUS,
    payload
  }
}

//-------------------------------------------

export const getFullATABaseTemplateList = () => {
  return function (dispatch, getState) {
    let params = {limit: 1000, page: 1, order: 'ata_base_template.id'};
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/ata-base-templates${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullATABaseTemplateListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATABaseTemplateList(response.data.items));
        dispatch(setFullATABaseTemplateListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full ATA Base Templates list!');
        dispatch(setFullATABaseTemplateListFetchStatus(RequestStatus.status.ERROR));
      });
  }
}

export function setFullATABaseTemplateList(payload) {
  return {
    type: SET_FULL_ATA_BASE_TEMPLATE_LIST,
    payload
  }
}

export function setFullATABaseTemplateListFetchStatus(payload) {
  return {
    type: SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS,
    payload
  }
}

//-------------------------------------------

export const getFullCiscoATABaseTemplateList = () => {
  return function (dispatch, getState) {
    let params = {limit: 1000, page: 1, order: 'ata_base_template.id'};
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/cisco-ata-base-templates${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullCiscoATABaseTemplateListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullCiscoATABaseTemplateList(response.data.items));
        dispatch(setFullCiscoATABaseTemplateListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full Cisco ATA Base Templates list!');
        dispatch(setFullCiscoATABaseTemplateListFetchStatus(RequestStatus.status.ERROR));
      });
  }
}

export function setFullCiscoATABaseTemplateList(payload) {
  return {
    type: SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST,
    payload
  }
}

export function setFullCiscoATABaseTemplateListFetchStatus(payload) {
  return {
    type: SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS,
    payload
  }
}

//-------------------------------------------

export const getFullDIDList = (data = {}) => {
  return function (dispatch, getState) {
    dispatch(setFullDIDListFetchStatus(RequestStatus.status.FETCHING));
    let params = {limit: 1000, page: 1, order: 'id'};
    Object.keys(data).forEach(key => {
      params[key] = data[key]
    });
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/dids${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullDIDList(response.data.items));
        dispatch(setFullDIDListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullDIDListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full DID list!');
      });
  }
}

export function setFullDIDList(payload) {
  return {
    type: SET_FULL_DID_LIST,
    payload
  }
}

export function setFullDIDListFetchStatus(payload) {
  return {
    type: SET_FULL_DID_LIST_FETCH_STATUS,
    payload
  }
}

//-------------------------------------------

export const getFullATAList = () => {
  return function (dispatch, getState) {
    let params = {limit: 1000, page: 1, order: 'id'};
    let queryString = Object.keys(params || {}).map(key => key + '=' + params[key]).join('&');
    let url = `${process.env.REACT_APP_API_URL}/atas${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATAList(response.data.items));
      })
      .catch(error => {
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during getting full ATA list!');
      });
  }
}

export function setFullATAList(payload) {
  return {
    type: SET_FULL_ATA_LIST,
    payload
  }
}

//-------------------------------------------

export function setSelectedCompany(payload) {
  return {
    type: SET_SELECTED_COMPANY,
    payload
  }
}
