import {
  DELETE_ATA_TYPES_DETAILS_STATUS,
  FETCH_ATA_TYPES_DETAILS_STATUS, FETCH_ATA_TYPES_LIST_STATUS,
  SET_ATA_TYPES_DETAILS,
  SET_ATA_TYPES_LIST,
  SET_ATA_TYPES_ROUTE_PARAMS
} from "./ataTypes.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'brand',
  q:''
}

const initialState = {
  ataTypeList: [],
  ataTypeListFetchStatus: null,
  ataTypeDetails: null,
  ataTypeDetailsFetchStatus: null,
  ataTypeDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function ataTypesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ATA_TYPES_LIST:
      return {...state, ataTypeList: action.payload}
    case FETCH_ATA_TYPES_LIST_STATUS:
      return {...state, ataTypeListFetchStatus: action.payload}
    case SET_ATA_TYPES_DETAILS:
      return {...state, ataTypeDetails: action.payload}
    case FETCH_ATA_TYPES_DETAILS_STATUS:
      return {...state, ataTypeDetailsFetchStatus: action.payload}
    case DELETE_ATA_TYPES_DETAILS_STATUS:
      return {...state, ataTypeDetailsDeleteStatus: action.payload}
    case SET_ATA_TYPES_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ataTypesReducer;
