export const SET_ATA_BASE_TEMPLATES_LIST = 'SET_ATA_BASE_TEMPLATES_LIST'
export const FETCH_ATA_BASE_TEMPLATES_LIST_STATUS = 'FETCH_ATA_BASE_TEMPLATES_LIST_STATUS'

export const SET_ATA_BASE_TEMPLATES_DETAILS = 'SET_ATA_BASE_TEMPLATES_DETAILS'
export const FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS'

export const DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS'
export const SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS = 'SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS'

export const POST_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'POST_ATA_BASE_TEMPLATES_DETAILS_STATUS'
export const PATCH_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'PATCH_ATA_BASE_TEMPLATES_DETAILS_STATUS'

export const PATCH_GS_BASE_CONFIG_DETAILS_STATUS = 'PATCH_GS_BASE_CONFIG_DETAILS_STATUS'
