import { connect } from 'react-redux';
import PrivateRoute from "./PrivateRoute";
import { getUserData } from "../Auth/auth.actions";

const mapStateToProps = (state, otherProps) => {
  return {
    user: state.authReducer.user,
    tokenChecked: state.authReducer.tokenChecked
  };
};

const mapDispatchToProps = dispatch => ({
  getUserData: (data = null) => {
    dispatch(getUserData(data));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
