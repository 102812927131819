import {
  getAPICredentialsListPath,
  getATABaseTemplatesListPath,
  getATAOrgTemplatesListPath,
  getATAsListPath,
  getATATypesListPath,
  getBillingListPath,
  getCiscoATABaseTemplatesListPath,
  getCiscoATAOrgTemplatesListPath,
  getCiscoATAsListPath,
  getDashboardPath,
  getDIDsListPath,
  getDIDsPath,
  getHomePath,
  getManageOrganizationsListPath,
  getManageUsersListPath,
  getOrganizationsPath,
  getPaymentHistoryPath,
  getPaymentMethodsListPath,
  getPBXsListPath,
  getPBXsPath
} from "../../routes";
import { ROLECONSTANTS } from "../../utils/constants";

export const menuItems = [
  {
    label: 'Home',
    path: getHomePath,
    icon: 'home_outlined',
    subItems: [
      {
        label: 'Dashboard',
        path: getDashboardPath,
        name: 'Dashboard',
        selectable: true
      },
      {
        label: 'Billing',
        name: 'Billing',
        selectable: true,
        allowedRoles: [ROLECONSTANTS.superadmin],
        subItems: [
          {
            label: 'Billing Overview',
            path: getBillingListPath,
            name: 'Billing',
            selectable: true,
          },
          {
            label: 'Payment Methods',
            path: getPaymentMethodsListPath,
            name: 'Payment Methods List',
            selectable: true,
            allowedRoles: [],
          },
          {
            label: 'Payment History',
            path: getPaymentHistoryPath,
            name: 'Payment History List',
            selectable: true,
            allowedRoles: [],
          }
        ]
      },

      //   {
      //     label: 'Billing',
      //     path: getBillingPath,
      //     subItems: [
      //       {
      //         label: 'Overview',
      //         path: getOverviewPath,
      //         name: 'Overview'
      //       },
      //       {
      //         label: 'Payment History',
      //         path: getPaymentHistoryPath,
      //         name: 'Payment History'
      //       },
      //       {
      //         label: 'Recurring Items',
      //         path: ''
      //       },
      //       {
      //         label: 'Payment Methods',
      //         path: ''
      //       },
      //       {
      //         label: 'Pricing',
      //         path: ''
      //       },
      //       {
      //         label: 'Preferences',
      //         path: ''
      //       }
      //     ]
      //   },
      //   {
      //     label: 'Usage',
      //     path: '',
      //     subItems: [
      //       {
      //         label: 'Summary',
      //         path: ''
      //       },
      //       {
      //         label: 'Detail',
      //         path: ''
      //       }
      //     ]
      //   }, {
      //     label: 'Notifications',
      //     path: '',
      //   },
      //   {
      //     label: 'Settings',
      //     path: '',
      //     subItems: [
      //       {
      //         label: 'General',
      //         path: ''
      //       },
      //       {
      //         label: 'Subaccounts',
      //         path: ''
      //       },
      //       {
      //         label: 'Manage Users',
      //         path: ''
      //       },
      //       {
      //         label: 'API Keys',
      //         path: ''
      //       }
      //     ]
      //   }
    ]
  },
  {
    label: 'Orgs',
    path: getOrganizationsPath,
    icon: 'business_outlined',
    allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
    subItems: [
      {
        label: 'Manage Orgs',
        path: getManageOrganizationsListPath,
        name: 'Manage Organization List',
        selectable: true,
        allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
        subItems: [
          {
            label: 'Organization Details',
            path: getManageOrganizationsListPath + '/:id',
            name: 'Organization Details',
            allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
            hide: true
          }
        ]
      },
      {
        label: 'Manage Users',
        path: getManageUsersListPath,
        name: 'Manage Users List',
        selectable: true,
        allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin],
        subItems: [
          {
            label: 'User Details',
            path: getManageUsersListPath + '/:id',
            name: 'User Details',
            allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin],
            hide: true
          }
        ]
      },
      {
        label: 'API Credentials',
        path: getAPICredentialsListPath,
        name: 'API Credentials List',
        selectable: true,
        allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin],
        subItems: [
          {
            label: 'API Credential Details',
            path: getAPICredentialsListPath + '/:id',
            name: 'API Credential Details',
            allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin],
            hide: true
          }
        ]
      },
    ]
  },
  {
    label: 'PBX\'s',
    path: getPBXsPath,
    icon: 'storage_outlined',
    allowedRoles: [],
    subItems: [
      {
        label: 'PBX\'s',
        path: getPBXsListPath,
        name: 'PBXs List',
        selectable: true,
        allowedRoles: [],
        subItems: [
          {
            label: 'PBX Details',
            path: getPBXsListPath + '/:id',
            name: 'PBX Details',
            allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
            hide: true
          }
        ]
      },
      // {
      //   label: 'Description',
      //   path: ''
      // },
      // {
      //   label: 'SIP Server 1 (Port)',
      //   path: ''
      // },
      // {
      //   label: 'SIP Server 2 (Port)',
      //   path: ''
      // },
      // {
      //   label: 'Outbound Proxy 1 (Port)',
      //   path: ''
      // },
      // {
      //   label: 'Outbound Proxy 1 (Port)',
      //   path: ''
      // },

      // {
      //   label: 'Check-Sync API',
      //   path: ''
      // },
    ]
  },
  {
    label: 'DID\'s',
    path: getDIDsPath,
    icon: 'phone_outlined',
    allowedRoles: [],
    subItems: [
      {
        label: 'Manage Numbers',
        path: '',
        subItems: [
          // {
          //   label: 'Number',
          //   path: ''
          // },
          // {
          //   label: 'Description',
          //   path: ''
          // },
          {
            label: 'Numbers',
            path: getDIDsListPath,
            name: 'Number List',
            selectable: true,
            // hideNested: true,
            allowedRoles: [],
            subItems: [
              {
                label: 'Number Details',
                path: getDIDsListPath + '/:id',
                name: 'Number Details',
                allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
                hide: true
              }
            ]
          },
          // {
          //   label: 'Port',
          //   path: ''
          // }
        ]
      }
    ]
  },
  {
    label: 'ATA\'s',
    icon: 'contact_phone_outlined',
    selectable: true,
    allowedRoles: [],
    subItems: [
      // {
      //   label: 'ATA',
      //   path: ''
      // },
      {
        label: 'ATA Types',
        path: getATATypesListPath,
        name: 'ATA Types List',
        selectable: true,
        allowedRoles: [ROLECONSTANTS.superadmin],
        subItems: [
          {
            label: 'ATA Type Details',
            path: getATATypesListPath + '/:id',
            name: 'ATA Type Details',
            allowedRoles: [ROLECONSTANTS.superadmin],
            hide: true
          }
        ]
      },
      {
        label: 'GrandStream',
        name: 'GrandStream',
        // selectable: true,
        allowedRoles: [],
        subItems: [
          {
            label: 'ATA\'s',
            path: getATAsListPath,
            name: 'ATAs List',
            selectable: true,
            allowedRoles: [],
            subItems: [
              {
                label: 'ATA Details',
                path: getATAsListPath + '/:id',
                name: 'ATA Details',
                allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
                hide: true
              }
            ]
          },
          {
            label: 'ATA Base Templates',
            path: getATABaseTemplatesListPath,
            name: 'ATA Base Templates List',
            selectable: true,
            allowedRoles: [ROLECONSTANTS.superadmin],
            subItems: [
              {
                label: 'ATA Base Template Details',
                path: getATABaseTemplatesListPath + '/:id',
                name: 'ATA Base Template Details',
                allowedRoles: [ROLECONSTANTS.superadmin],
                hide: true
              }
            ]
          },
          {
            label: 'ATA Org Templates',
            path: getATAOrgTemplatesListPath,
            name: 'ATA Org Templates List',
            selectable: true,
            allowedRoles: [ROLECONSTANTS.superadmin],
            subItems: [
              {
                label: 'ATA Org Template Details',
                path: getATAOrgTemplatesListPath + '/:id',
                name: 'ATA Org Template Details',
                allowedRoles: [ROLECONSTANTS.superadmin],
                hide: true
              }
            ]
          },
          // {
          //   label: 'ATA Ports',
          //   path: getATAPortsListPath,
          //   name: 'ATA Ports List',
          //   selectable: true,
          //   subItems: [
          //     {
          //       label: 'ATA Port Details',
          //       path: getATAPortsListPath + '/:id',
          //       name: 'ATA Port Details',
          //       hide: true
          //     }
          //   ]
          // },
          // {
          //   label: 'Status',
          //   path: ''
          // },
          // {
          //   label: 'Description',
          //   path: ''
          // },
          // {
          //   label: 'MAC Address',
          //   path: ''
          // },
          // {
          //   label: 'Model',
          //   path: ''
          // },
          // {
          //   label: 'IP',
          //   path: ''
          // },
          // {
          //   label: 'Ports',
          //   path: '',
          //   subItems: [
          //     {
          //       label: '1 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '2 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '3 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '4 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '5 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '6 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '7 - Phone Number (Status)',
          //       path: ''
          //     },
          //     {
          //       label: '8 - Phone Number (Status)',
          //       path: ''
          //     }
          //   ]
          // }
        ]
      },
      {
        label: 'Cisco',
        name: 'Cisco',
        selectable: true,
        allowedRoles: [],
        subItems: [
          {
            label: 'ATA\'s',
            path: getCiscoATAsListPath,
            name: 'Cisco ATAs List',
            selectable: true,
            allowedRoles: [],
            subItems: [
              {
                label: 'ATA Details',
                path: getCiscoATAsListPath + '/:id',
                name: 'Cisco ATA Details',
                allowedRoles: [ROLECONSTANTS.superadmin, ROLECONSTANTS.admin, ROLECONSTANTS.user],
                hide: true
              }
            ]
          },
          {
            label: 'ATA Base Templates',
            path: getCiscoATABaseTemplatesListPath,
            name: 'Cisco ATA Base Templates List',
            selectable: true,
            allowedRoles: [ROLECONSTANTS.superadmin],
            subItems: [
              {
                label: 'ATA Base Template Details',
                path: getCiscoATABaseTemplatesListPath + '/:id',
                name: 'Cisco ATA Base Template Details',
                allowedRoles: [ROLECONSTANTS.superadmin],
                hide: true
              }
            ]
          },
          {
            label: 'ATA Org Templates',
            path: getCiscoATAOrgTemplatesListPath,
            name: 'Cisco ATA Org Templates List',
            selectable: true,
            allowedRoles: [ROLECONSTANTS.superadmin],
            subItems: [
              {
                label: 'ATA Org Template Details',
                path: getCiscoATAOrgTemplatesListPath + '/:id',
                name: 'Cisco ATA Org Template Details',
                allowedRoles: [ROLECONSTANTS.superadmin],
                hide: true
              }
            ]
          },
        ]
      }
    ]
  }
]
