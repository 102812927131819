export const SET_API_CREDENTIALS_LIST = 'SET_API_CREDENTIALS_LIST'
export const FETCH_API_CREDENTIALS_LIST_STATUS = 'FETCH_API_CREDENTIALS_LIST_STATUS'

export const SET_API_CREDENTIALS_DETAILS = 'SET_API_CREDENTIALS_DETAILS'
export const FETCH_API_CREDENTIALS_DETAILS_STATUS = 'FETCH_API_CREDENTIALS_DETAILS_STATUS'

export const DELETE_API_CREDENTIALS_DETAILS_STATUS = 'DELETE_API_CREDENTIALS_DETAILS_STATUS'
export const SET_API_CREDENTIALS_ROUTE_PARAMS = 'SET_API_CREDENTIALS_ROUTE_PARAMS'

export const POST_API_CREDENTIALS_DETAILS_STATUS = 'POST_API_CREDENTIALS_DETAILS_STATUS'
export const PATCH_API_CREDENTIALS_DETAILS_STATUS = 'PATCH_API_CREDENTIALS_DETAILS_STATUS'
