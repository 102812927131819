import {
  DELETE_ATA_PORTS_DETAILS_STATUS,
  FETCH_ATA_PORTS_DETAILS_STATUS,
  FETCH_ATA_PORTS_LIST_STATUS,
  SET_ATA_PORTS_DETAILS,
  SET_ATA_PORTS_LIST,
  SET_ATA_PORTS_ROUTE_PARAMS
} from "./ataPorts.actionTypes";

const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'ata',
  // orgId: '5'
}

const initialState = {
  ataPortList: [],
  ataPortListFetchStatus: null,
  ataPortDetails: null,
  ataPortDetailsFetchStatus: null,
  ataPortDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function ataPortsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ATA_PORTS_LIST:
      return {...state, ataPortList: action.payload}
    case FETCH_ATA_PORTS_LIST_STATUS:
      return {...state, ataPortListFetchStatus: action.payload}
    case SET_ATA_PORTS_DETAILS:
      return {...state, ataPortDetails: action.payload}
    case FETCH_ATA_PORTS_DETAILS_STATUS:
      return {...state, ataPortDetailsFetchStatus: action.payload}
    case DELETE_ATA_PORTS_DETAILS_STATUS:
      return {...state, ataPortDetailsDeleteStatus: action.payload}
    case SET_ATA_PORTS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ataPortsReducer;
