import {
  FETCH_GDMS_UPDATE_STATUS
} from "./GDMS.actionTypes";

const initialState = {
  gdmsFetchStatus: null,
};

function gdmsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GDMS_UPDATE_STATUS:
      return {...state, gdmsFetchStatus: action.payload}
  }
  return state;
}

export default gdmsReducer;
