import { SET_MODAL_CONTENT, SHOW_MODAL } from "./utils.actionTypes";

export function changeModalState(payload) {
  return {
    type: SHOW_MODAL,
    payload
  }
}

export function setModalContent(payload) {
  return {
    type: SET_MODAL_CONTENT,
    payload
  }
}
