import { createBrowserHistory } from "history";
import routes, { getDashboardPath } from "../routes";
import { matchPath } from "react-router-dom";
import { menuItems } from "../containers/Layout/menuItems";
import SnackbarUtils from "./SnackbarUtils";
import { store } from "../store";

export const history = createBrowserHistory();

export const countryCodes = [
  {
    country: 'US',
    code: '+1',
    mask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
];

export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export const getSettings = () => {
  let settings: any = localStorage.getItem('settings') || null;
  try {
    if (settings && JSON.parse(settings)) {
      settings = JSON.parse(settings);
    } else {
      settings = {};
    }
  } catch {
    settings = {};
  }
  return settings
}

const addParent = (parent) => {
  parent.subItems.forEach(y => {
    if (y.hasOwnProperty('subItems') && y.subItems && y.subItems.length) {
      addParent({...y});
    }
    y.parent = {...parent};
  })
}

export const getCurrentPathFromRouteTree = (tree, value, key = 'id', reverse = false) => {
  tree.forEach(x => {
    addParent(x);
  })

  const stack = [...tree];
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']()
    if (node[key] === value) return node
    node.subItems && stack.push(...node.subItems)
  }
  return null
}

export const checkSubpageAvailability = () => {
  let user = store.getState().authReducer.user;
  let route = routes.find(
    route => matchPath(history.location.pathname, route)
  )

  // @ts-ignore
  let currentRoute = getCurrentPathFromRouteTree(menuItems, route.name, 'name', true);
  if (currentRoute.hasOwnProperty('allowedRoles') && currentRoute.allowedRoles && currentRoute.allowedRoles.length) {
    if (!currentRoute.allowedRoles.includes(user.role)) {
      SnackbarUtils.warning('You do not have permission for this resource');
      history.push(getDashboardPath);
    }
  }
}
