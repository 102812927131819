import {
  FETCH_SYNC_WITH_REVIO_UPDATE_STATUS
} from "./Revio.actionTypes";

const initialState = {
  syncRevioFetchStatus: null,
};

function revioReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYNC_WITH_REVIO_UPDATE_STATUS:
      return {...state, syncRevioFetchStatus: action.payload}
  }
  return state;
}

export default revioReducer;
