import {
  DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS,
  FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS, FETCH_ATA_BASE_TEMPLATES_LIST_STATUS,
  SET_ATA_BASE_TEMPLATES_DETAILS,
  SET_ATA_BASE_TEMPLATES_LIST,
  SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS
} from "./ataBaseTemplates.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'ata_base_template.name',
  q:''
}

const initialState = {
  ataBaseTemplateList: [],
  ataBaseTemplateListFetchStatus: null,
  ataBaseTemplateDetails: null,
  ataBaseTemplateDetailsFetchStatus: null,
  ataBaseTemplateDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function ataBaseTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ATA_BASE_TEMPLATES_LIST:
      return {...state, ataBaseTemplateList: action.payload}
    case FETCH_ATA_BASE_TEMPLATES_LIST_STATUS:
      return {...state, ataBaseTemplateListFetchStatus: action.payload}
    case SET_ATA_BASE_TEMPLATES_DETAILS:
      return {...state, ataBaseTemplateDetails: action.payload}
    case FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS:
      return {...state, ataBaseTemplateDetailsFetchStatus: action.payload}
    case DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS:
      return {...state, ataBaseTemplateDetailsDeleteStatus: action.payload}
    case SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ataBaseTemplatesReducer;
