import {
  DELETE_ORGANIZATIONS_DETAILS_STATUS,
  FETCH_ORGANIZATIONS_DETAILS_STATUS, FETCH_ORGANIZATIONS_LIST_STATUS,
  SET_ORGANIZATIONS_DETAILS,
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATIONS_ROUTE_PARAMS
} from "./organizations.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'organization.name',
  q: ''
}

const initialState = {
  organizationList: [],
  organizationListFetchStatus: null,
  organizationDetails: null,
  organizationDetailsFetchStatus: null,
  organizationDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function organizationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATIONS_LIST:
      return {...state, organizationList: action.payload}
    case FETCH_ORGANIZATIONS_LIST_STATUS:
      return {...state, organizationListFetchStatus: action.payload}
    case SET_ORGANIZATIONS_DETAILS:
      return {...state, organizationDetails: action.payload}
    case FETCH_ORGANIZATIONS_DETAILS_STATUS:
      return {...state, organizationDetailsFetchStatus: action.payload}
    case DELETE_ORGANIZATIONS_DETAILS_STATUS:
      return {...state, organizationDetailsDeleteStatus: action.payload}
    case SET_ORGANIZATIONS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default organizationsReducer;
