import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme, styled } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    customizeToolbar: {
      minHeight: 30,
      background: '#0d122b'
    },
    menu: {
      fontSize: '12px'
    }
  }),
);

const UserDropdown = styled(Button)({
  color: 'white'
})

const Header = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    props.logout();
    handleClose();
  }

  return (
    <AppBar position="static">
      <Toolbar className={classes.customizeToolbar}>
        <Box display="flex" flexGrow="1" alignItems="center">
          <Box mx={2}>
            <img src={require('../../assets/images/logo.png')} style={{height: '20px'}}/>
          </Box>
          <Typography variant="h6" className={classes.title} style={{fontSize: '17px'}}>
            Star Server
          </Typography>
        </Box>
        <>
          <UserDropdown aria-controls="simple-menu" aria-haspopup="true" className={classes.menu} onClick={handleClick}>
            Hello {props?.user?.firstname} {props?.user?.lastname}! <Box mx={1}><FontAwesomeIcon
            icon={faAngleDown}/></Box>
          </UserDropdown>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
          >
            <MenuItem>Settings</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </>
        {/*<Button color="inherit">Login</Button>*/}
      </Toolbar>
    </AppBar>
  )
}

export default React.memo(Header);
Header.whyDidYouRender = true
