import { SET_CHARTS_DATA, SET_STATS_DATA } from "./stats.actionTypes";

const initialState = {
  statsData: null,
  chartsData: null
};

function statsReducers(state = initialState, action) {
  switch (action.type) {
    case SET_STATS_DATA:
      return {...state, statsData: action.payload}
    case SET_CHARTS_DATA:
      return {...state, chartsData: action.payload}
  }
  return state;
}

export default statsReducers;
