import {
  DELETE_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS,
  FETCH_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS, FETCH_CISCO_ATA_BASE_TEMPLATES_LIST_STATUS,
  SET_CISCO_ATA_BASE_TEMPLATES_DETAILS,
  SET_CISCO_ATA_BASE_TEMPLATES_LIST,
  SET_CISCO_ATA_BASE_TEMPLATES_ROUTE_PARAMS
} from "./ciscoAtaBaseTemplates.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'ata_base_template.name',
  q:''
}

const initialState = {
  ciscoAtaBaseTemplateList: [],
  ciscoAtaBaseTemplateListFetchStatus: null,
  ciscoAtaBaseTemplateDetails: null,
  ciscoAtaBaseTemplateDetailsFetchStatus: null,
  ciscoAtaBaseTemplateDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function ciscoAtaBaseTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CISCO_ATA_BASE_TEMPLATES_LIST:
      return {...state, ciscoAtaBaseTemplateList: action.payload}
    case FETCH_CISCO_ATA_BASE_TEMPLATES_LIST_STATUS:
      return {...state, ciscoAtaBaseTemplateListFetchStatus: action.payload}
    case SET_CISCO_ATA_BASE_TEMPLATES_DETAILS:
      return {...state, ciscoAtaBaseTemplateDetails: action.payload}
    case FETCH_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS:
      return {...state, ciscoAtaBaseTemplateDetailsFetchStatus: action.payload}
    case DELETE_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS:
      return {...state, ciscoAtaBaseTemplateDetailsDeleteStatus: action.payload}
    case SET_CISCO_ATA_BASE_TEMPLATES_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ciscoAtaBaseTemplatesReducer;
