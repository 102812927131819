import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { menuItems } from "../../containers/Layout/menuItems";
import { matchPath, useLocation, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, styled } from "@material-ui/core/styles";
import routes from "../../routes";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import { ROLECONSTANTS } from "../../utils/constants";

const useStyles = makeStyles({
  arrow: {
    margin: '0 15px 0 5px'
  }
});

const BreadcrumbElement = styled('span')({
  fontSize: '14px',
  whiteSpace: "nowrap"
})

const CompanyLocationEllipsis = styled('span')({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  textAlign: 'left'
})

const SubheaderBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  '@media screen and (max-width: 800px)': {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  }
})

const CompanyButton = styled(Button)({
  minWidth: '250px',

  '@media screen and (max-width: 800px)': {
    width: '100%'
  }
})

const SubheaderContainer = styled(Box)({
  padding: '12px',

  '@media screen and (max-width: 800px)': {
    padding: '4px'
  }
})

const SubHeader = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);
  const [companies, setCompanies] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (props.user) {
      let companies: Array<any> = getCompanies(props.user.organization, '');
      if (companies && companies.length > 1) {
        companies = [...companies];
        if (props.user && (props.user.organization.children?.length || props.user.role === ROLECONSTANTS.superadmin)) {
          companies = [{id: 'all', name: 'All Orgs'}, ...companies];
        }
      }
      setCompanies(companies);
    }
  }, [props.user])

  useEffect(() => {
    if (companies && companies.length) {
      let savedCompany: any = localStorage.getItem('selected_company');
      if (savedCompany) {
        try {
          savedCompany = JSON.parse(savedCompany);
        } catch {
          savedCompany = null
        }
        if (typeof savedCompany === 'object' && savedCompany !== null && savedCompany.hasOwnProperty('id')) {
          let found = companies.find(x => x.id === savedCompany['id']);
          props.setSelectedCompany(found || companies[0]);
          localStorage.setItem('selected_company', JSON.stringify(found || companies[0]));
        } else {
          props.setSelectedCompany(companies[0]);
          localStorage.setItem('selected_company', JSON.stringify(companies[0]));
        }
      } else {
        props.setSelectedCompany(companies[0]);
        localStorage.setItem('selected_company', JSON.stringify(companies[0]));
      }
    }
  }, [companies])

  const getCompanies = (company, y) => {
    let ar = [];
    if (company) {

      // @ts-ignore
      ar.push({id: company.id, name: `${y} ${company.name}`});
      if (company.children) {
        company.children.forEach(x => {
          ar = [...ar, ...getCompanies(x, y + '\t-')];
        })

      } else return ar;
    }
    return ar
  }

  useEffect(() => {
    let currentRoute = routes.find(
      route => matchPath(location.pathname, route)
    )
    // @ts-ignore
    let a = getCurrentPathFromRouteTree(menuItems, currentRoute.name, 'name', true);
    let b = createBreadcrumbPath(a);
    setBreadcrumbs(b);

  }, [props.location.pathname])

  const createBreadcrumbPath = (route, array?) => {
    let history: Array<any> = [];

    if (route) {
      if (route.parent) {
        let ret = createBreadcrumbPath(route.parent);
        history = [...history, ...ret, ...[{label: route.label, path: route.path}]];
      } else {
        return [{label: route.label, path: route.path}];
      }
    }
    return history;
  }

  const addParent = (parent) => {
    parent.subItems.forEach(y => {
      if (y.hasOwnProperty('subItems') && y.subItems && y.subItems.length) {
        addParent({...y});
      }
      y.parent = {...parent};
    })
  }

  const getCurrentPathFromRouteTree = (tree, value, key = 'id', reverse = false) => {
    tree.forEach(x => {
      addParent(x);
    })

    const stack = [...tree];
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']()
      if (node[key] === value) return node
      node.subItems && stack.push(...node.subItems)
    }
    return null
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeCompany = company => e => {
    props.setSelectedCompany(company);
    localStorage.setItem('selected_company', JSON.stringify(company));
    handleClose();
  }

  const getName = name => {
    return name.replace(/[-\t+]/g, "");
  }

  return (
    <SubheaderContainer bgcolor="action.selected">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <SubheaderBox>
          {props.selectedCompany &&
          <>
              <CompanyButton aria-controls="organization-dropdown" aria-haspopup="true" onClick={handleClick}>
                  <CompanyLocationEllipsis><b>{getName(props.selectedCompany.name)}</b></CompanyLocationEllipsis>
                  <Box mx={1}><FontAwesomeIcon
                      icon={faAngleDown}/></Box>
              </CompanyButton>
              <Menu
                  id="organization-dropdown"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{vertical: "bottom", horizontal: "left"}}
              >
                {companies && companies.map(company => <MenuItem key={company.id}
                                                                 onClick={changeCompany(company)}>{company.name}</MenuItem>)}
              </Menu>
          </>
          }
          <Box display="flex" alignItems="center" style={{height: '24px', width: '100%', overflow: 'hidden'}}>
            <Box mx={1} display="flex" style={{overflowX: 'scroll'}}>
              {breadcrumbs.map((item, i) => {
                return <BreadcrumbElement key={i}>{item.label} {i < breadcrumbs.length - 1 ?
                  <FontAwesomeIcon className={classes.arrow} icon={faChevronRight}/> : ''}</BreadcrumbElement>
              })}
            </Box>
          </Box>
        </SubheaderBox>
      </Grid>
    </SubheaderContainer>
  )
}

export default withRouter(React.memo(SubHeader));
SubHeader.whyDidYouRender = true
