const initial_state = {
  requestCount: 0
};

function loaderReducer(state = initial_state, action) {
  if ((action.type.indexOf('FETCH') >= 0 || action.type.indexOf('PATCH') >= 0 || action.type.indexOf('POST') >= 0 ||  action.type.indexOf('DELETE') >= 0
    || action.type.indexOf('PASSWORD_RESET') >= 0 || action.type.indexOf('UPLOAD') >= 0) && action.payload) {

    var count = state.requestCount;
    if (action.payload.toLowerCase() === 'fetching')
      count++;

    if (action.payload.toLowerCase() === 'error' || action.payload.toLowerCase() === 'done')
      count = count > 0 ? --count : 0;

    return {...state, requestCount: count}
  }
  return {...state};
}

export default loaderReducer;
