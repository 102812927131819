import React, { useEffect, useState, Component, JSXElementConstructor } from "react";
import { Redirect, Route } from "react-router-dom";
import NetworkService from "../../utils/network.service";
import { store } from "../../store";
import { history } from "../../utils/utilities";

const PrivateRoute = (props) => {
  const [isLoading, setIsLoading] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      NetworkService.setupDefaultHeaders(token)
    }
    NetworkService.setupInterceptors(store);
    setIsLoading(true);
    props.getUserData(true);
  }, []);

  useEffect(() => {
    if (!props.user)
      return
    if (props.tokenChecked) {
      setIsLoading(false);
      if (props.user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        history.push('/login')
      }
    }
  }, [props.user])

  const Component: JSXElementConstructor<any> = props.component;
  return (<>
      {isLoading ? <></>
        : <Route {...props.rest}
                 render={
                   props => isAuthenticated ?
                     <Component {...props} /> :
                     <Redirect to="/"/>
                 }
        />
      }
    </>
    // <Route {...props.rest}
    //        render={
    //          props => isLoading
    //            ? <></>
    //            : isAuthenticated ?
    //              <Component {...props} /> :
    //              <Redirect to="/"/>
    //        }
    // />
  )
}
export default React.memo(PrivateRoute);



//
// import React, { useEffect, useState, Component, JSXElementConstructor } from "react";
// import { Redirect, Route } from "react-router-dom";
// import NetworkService from "../../utils/network.service";
// import { store } from "../../store";
// import { history } from "../../utils/utilities";
//
// const PrivateRoute = (props) => {
//   const [isLoading, setIsLoading] = useState<any>(null);
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
//
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       NetworkService.setupDefaultHeaders(token)
//     }
//     NetworkService.setupInterceptors(store);
//     setIsLoading(true);
//     props.getUserData(true);
//   }, []);
//
//   useEffect(() => {
//     if (!props.user)
//       return
//     if (props.tokenChecked) {
//       setIsLoading(false);
//       if (props.user) {
//         setIsAuthenticated(true);
//       } else {
//         setIsAuthenticated(false);
//         history.push('/login')
//       }
//     }
//   }, [props.user])
//
//   const Component: JSXElementConstructor<any> = props.component;
//   return (<>
//       {isLoading ? <></>
//         : <Route {...props.rest}
//                  render={
//                    props => isAuthenticated ?
//                      <Component {...props} /> :
//                      <Redirect to="/"/>
//                  }
//         />
//       }
//     </>
//     // <Route {...props.rest}
//     //        render={
//     //          props => isLoading
//     //            ? <></>
//     //            : isAuthenticated ?
//     //              <Component {...props} /> :
//     //              <Redirect to="/"/>
//     //        }
//     // />
//   )
// }
// export default React.memo(PrivateRoute);
