import React, { useEffect, useState } from "react";
import routes from "../../routes";

import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Header from "../../components/Header";
import SubHeader from "../../components/SubHeader";
import { menuItems } from "./menuItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faSync } from "@fortawesome/free-solid-svg-icons";
import DrawerMenuLink from "../../components/DrawerMenuLink/DrawerMenuLink";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityGuard from "../../components/VisibilityGuard";

const Skeleton = styled('div')({
  // border: '1px solid green',
  height: '100vh',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
})

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  // border: '1px solid blue',
  overflow: 'hidden'
})

const SideMenu = styled(Box)({
  width: '60px',
  height: '100%',
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#e6e6e6',
  '@media screen and (max-width: 800px)': {
    width: '45px',
    paddingBottom: '30px'
  },
  overflowY: 'scroll'
})

const DrawerContainer = styled(Box)({
  width: '200px',
  borderRight: '1px solid #e6e6e6',
  backgroundColor: '#F6F6F6',
  '@media screen and (max-width: 800px)': {
    fontSize: '14px'
  }
})

const MenuItem = styled(Box)({
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.active': {
    background: '#f5f5f5',
    fontWeight: "bold",
    color: '#99cc33'
  },
  color: '#6c6c6c',
  '@media screen and (max-width: 800px)': {
    height: '50px',
  }
})

const MainContent = styled(Box)({
  padding: '10px 20px 40px 20px',
  width: '100%',
  overflowY: 'scroll'
})

const CustomMenuIcon = styled(Icon)({
  '@media screen and (max-width: 800px)': {
    fontSize: '1.3em',
  }
})

const Layout = (props) => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(true);
  const [subItems, setSubItems] = useState<any>(null);
  const history = useHistory();

  /**
   * Expand drawer on first app loading
   */
  useEffect(() => {
    let found = menuItems.find(x => {
      return x.path && props.location.pathname.indexOf(x.path) !== -1
    })
    if (found) {
      setSubItems(found.subItems);
    }
  }, [props.location.pathname])

  /**
   * Drawer toggle func
   */
  const toggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  }

  const findPath = (item) => {
    if (!item.hasOwnProperty('selectable')) {
      if (!item.selectable && item.hasOwnProperty('subItems') && item.subItems.length) {
        return findPath(item.subItems[0]);
      } else {
        return null
      }
    }
    return item.path;
  }

  const updateStatus = () => {
    props.updateStatus();
  }

  /**
   * Get proper submenu items to show it on drawer
   * @param item
   */
  const selectSubmenu = item => e => {
    let foundedUrl = findPath(item);
    if (foundedUrl === props.location.pathname)
      return
    if (foundedUrl) history.push(foundedUrl);
    setSubItems(item.subItems);
    setVisibleDrawer(true);
  }

  /**
   * Mark current menu item as active
   * @param route
   */
  const markRouteAsActive = route => {
    const formattedLocationPath = props.location.pathname.replace(/^(.*)\/\d*$/, '$1') || ''
    return route.path && formattedLocationPath === route.path
  }

  return (
    <Skeleton>
      <Dialog
        open={props.showModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {props.modalContent}
      </Dialog>
      <Header/>
      <SubHeader/>
      <ContentContainer>
        <SideMenu>
          <Box>
            {menuItems.map((item, i) =>
              <VisibilityGuard allowedRoles={item.allowedRoles} key={item.label}>
                <Tooltip title={item.label} placement="left" arrow>
                  <MenuItem
                    className={markRouteAsActive(item) ? 'active' : ''}
                    onClick={selectSubmenu(item)}>
                    <CustomMenuIcon>{item.icon}</CustomMenuIcon>
                  </MenuItem>
                </Tooltip>
              </VisibilityGuard>
            )}

            <MenuItem onClick={updateStatus}>
              <Tooltip title={'Update Status'} placement="left" arrow>
                <Box><FontAwesomeIcon icon={faSync}/></Box>
              </Tooltip>
            </MenuItem>
          </Box>

          <MenuItem onClick={toggleDrawer}>
            {visibleDrawer && subItems ? <FontAwesomeIcon icon={faAngleDoubleLeft}/> :
              <FontAwesomeIcon icon={faAngleDoubleRight}/>}
          </MenuItem>
        </SideMenu>
        {visibleDrawer && subItems
          ? <DrawerContainer px={2} py={1}>
            {subItems && subItems.map((subItem, i) =>
              <VisibilityGuard allowedRoles={subItem.allowedRoles} key={subItem.label} renderAlways={true}>
                <Box py={1}>
                  {subItem && subItem.subItems && subItem.subItems.length && (subItem.subItems.filter(x => !x.hasOwnProperty('hide') || !x.hide).length)
                    ? <>
                      <DrawerMenuLink className={markRouteAsActive(subItem) ? 'active' : ''}
                                      label={subItem.label}/>
                      {subItem.subItems.map((item, j) =>
                        <DrawerMenuLink className={markRouteAsActive(item) ? 'active' : ''}
                                        key={j}
                                        label={item.label}
                                        path={item.path}
                                        allowedRoles={item.allowedRoles}
                                        static={false}
                                        nested={true}
                        />
                      )
                      }</>
                    :
                    <DrawerMenuLink className={markRouteAsActive(subItem) ? 'active' : ''}
                                    label={subItem.label}
                                    path={subItem.path}
                                    static={false}/>
                  }
                </Box>
              </VisibilityGuard>
            )}
          </DrawerContainer>
          : null}
        <MainContent>
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  // name={route.name}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}
          </Switch>
        </MainContent>
      </ContentContainer>
    </Skeleton>
  )
}

export default withRouter(Layout);
Layout.whyDidYouRender = true
