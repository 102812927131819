import {
  DELETE_CISCO_ATA_ORG_TEMPLATES_DETAILS_STATUS,
  FETCH_CISCO_ATA_ORG_TEMPLATES_DETAILS_STATUS,
  FETCH_CISCO_ATA_ORG_TEMPLATES_LIST_STATUS,
  SET_CISCO_ATA_ORG_TEMPLATES_DETAILS,
  SET_CISCO_ATA_ORG_TEMPLATES_LIST,
  SET_CISCO_ATA_ORG_TEMPLATES_ROUTE_PARAMS
} from "./ciscoAtaOrgTemplates.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'ata_org_template.name',
  q: ''
}

const initialState = {
  ciscoAtaOrgTemplateList: [],
  ciscoAtaOrgTemplateListFetchStatus: null,
  ciscoAtaOrgTemplateDetails: null,
  ciscoAtaOrgTemplateDetailsFetchStatus: null,
  ciscoAtaOrgTemplateDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function ciscoAtaOrgTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CISCO_ATA_ORG_TEMPLATES_LIST:
      return {...state, ciscoAtaOrgTemplateList: action.payload}
    case FETCH_CISCO_ATA_ORG_TEMPLATES_LIST_STATUS:
      return {...state, ciscoAtaOrgTemplateListFetchStatus: action.payload}
    case SET_CISCO_ATA_ORG_TEMPLATES_DETAILS:
      return {...state, ciscoAtaOrgTemplateDetails: action.payload}
    case FETCH_CISCO_ATA_ORG_TEMPLATES_DETAILS_STATUS:
      return {...state, ciscoAtaOrgTemplateDetailsFetchStatus: action.payload}
    case DELETE_CISCO_ATA_ORG_TEMPLATES_DETAILS_STATUS:
      return {...state, ciscoAtaOrgTemplateDetailsDeleteStatus: action.payload}
    case SET_CISCO_ATA_ORG_TEMPLATES_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ciscoAtaOrgTemplatesReducer;
