import { connect } from 'react-redux';
import Header from "./Header";
import { logout } from "../../containers/Auth/auth.actions";

const mapStateToProps = (state, otherProps) => {
  return {
    user: state.authReducer.user
  };
};
const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
