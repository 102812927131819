export const SET_DIDS_LIST = 'SET_DIDS_LIST'
export const FETCH_DIDS_LIST_STATUS = 'FETCH_DIDS_LIST_STATUS'

export const SET_DIDS_DETAILS = 'SET_DIDS_DETAILS'
export const FETCH_DIDS_DETAILS_STATUS = 'FETCH_DIDS_DETAILS_STATUS'

export const DELETE_DIDS_DETAILS_STATUS = 'DELETE_DIDS_DETAILS_STATUS'
export const SET_DIDS_ROUTE_PARAMS = 'SET_DIDS_ROUTE_PARAMS'

export const POST_DIDS_DETAILS_STATUS = 'POST_DIDS_DETAILS_STATUS'
export const POST_DIDS_BULK_DETAILS_STATUS = 'POST_DIDS_BULK_DETAILS_STATUS'
export const PATCH_DIDS_DETAILS_STATUS = 'PATCH_DIDS_DETAILS_STATUS'

export const SET_DID_BULK = 'SET_DID_BULK'
