export const SET_ORGANIZATION_BALANCE_DATA = 'SET_ORGANIZATION_BALANCE_DATA'
export const FETCH_ORGANIZATION_BALANCE_DATA_STATUS = 'FETCH_ORGANIZATION_BALANCE_DATA_STATUS'

export const POST_PAYMENT_STATUS = 'POST_PAYMENT_STATUS'

export const SET_BILLING_DATA = 'SET_BILLING_DATA'
export const SET_FILES_DATA = 'SET_FILES_DATA'
export const FETCH_BILLING_DATA_STATUS = 'FETCH_BILLING_DATA_STATUS'
export const FETCH_FILES_DATA_STATUS = 'FETCH_FILES_DATA_STATUS'
export const UPLOAD_ORG_FILE_DATA_STATUS = 'UPLOAD_ORG_FILE_DATA_STATUS'
export const SET_BILLING_ROUTE_PARAMS = 'SET_BILLING_ROUTE_PARAMS'


