import {
  FETCH_FORGOT_PASSWORD_STATUS,
  FETCH_LOGIN_STATUS, FETCH_RESTORE_PASSWORD_STATUS,
  FETCH_USER_DATA_STATUS,
  SET_USER_DATA,
  TOKEN_CHECKED
} from "./auth.actionTypes";
import axios from 'axios';
import SnackbarUtils from "../../utils/SnackbarUtils";
import NetworkService from "../../utils/network.service";
import { history } from "../../utils/utilities";
import { RequestStatus } from "../../utils/RequestStatus";

export const login = (data?) => {
  return function (dispatch, getState) {
    let url = `${process.env.REACT_APP_API_URL}/auth/login/`;
    dispatch(setLoginFetchStatus(RequestStatus.status.FETCHING))
    axios
      .post(url, data)
      .then(response => {
        dispatch(setUserData(response.data.user));
        localStorage.setItem('token', response.data.access_token);
        NetworkService.setupDefaultHeaders(response.data.access_token)
        dispatch(setTokenChecked(true));
        SnackbarUtils.success('You\'re logged in!');
        let refURL = new URLSearchParams(window.location.search).get('ref');
        if (refURL) {
          refURL = decodeURIComponent(refURL)
        }
        history.push(refURL || '/home/dashboard');
        dispatch(setLoginFetchStatus(RequestStatus.status.DONE))
      })
      .catch(error => {
        dispatch(setLoginFetchStatus(RequestStatus.status.ERROR))
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during login!');
      });
  }
}

export function setLoginFetchStatus(payload) {
  return {
    type: FETCH_LOGIN_STATUS,
    payload
  }
}

export const getUserData = (initialCall?) => {
  return function (dispatch, getState) {
    let url = `${process.env.REACT_APP_API_URL}/users/profile/`;
    dispatch(setUserDataFetchStatus(RequestStatus.status.FETCHING))
    axios
      .get(url)
      .then(response => {
        if (initialCall) {
          dispatch(setTokenChecked(true));
        }
        dispatch(setUserData(response.data))
        dispatch(setUserDataFetchStatus(RequestStatus.status.DONE))
      })
      .catch(error => {
        dispatch(setUserData(null))
        dispatch(setUserDataFetchStatus(RequestStatus.status.ERROR))
      });
  }
}

export const logout = () => {
  return function (dispatch, getState) {
    localStorage.removeItem('token');
    localStorage.removeItem('selected_company');
    delete axios.defaults.headers.common["Authorization"];
    dispatch(setUserData(null))
    dispatch(setTokenChecked(false));
    history.push('/');
  }
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload
  }
}

export function setTokenChecked(payload) {
  return {
    type: TOKEN_CHECKED,
    payload
  }
}

export function setUserDataFetchStatus(payload) {
  return {
    type: FETCH_USER_DATA_STATUS,
    payload
  }
}


export const forgotPassword = (data?) => {
  return function (dispatch, getState) {
    let url = `${process.env.REACT_APP_API_URL}/users/forgotPassword/`;
    dispatch(setForgotPasswordFetchStatus(RequestStatus.status.FETCHING))
    axios
      .post(url, data)
      .then(response => {
        dispatch(setUserData(response.data));
        SnackbarUtils.success('Check your e-mail for reset password link');
        dispatch(setForgotPasswordFetchStatus(RequestStatus.status.DONE))
        history.push('/login');
      })
      .catch(error => {
        dispatch(setForgotPasswordFetchStatus(RequestStatus.status.ERROR))
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during sending reset mail!');
      });
  }
}

export function setForgotPasswordFetchStatus(payload) {
  return {
    type: FETCH_FORGOT_PASSWORD_STATUS,
    payload
  }
}


export const restorePassword = (data?) => {
  return function (dispatch, getState) {
    let url = `${process.env.REACT_APP_API_URL}/users/restorePassword/`;
    dispatch(setRestorePasswordFetchStatus(RequestStatus.status.FETCHING))
    axios
      .post(url, data)
      .then(response => {
        dispatch(setUserData(response.data));
        SnackbarUtils.success('Password has been changed. Now you can log in using your new password');
        dispatch(setRestorePasswordFetchStatus(RequestStatus.status.DONE))
        history.push('/login');
      })
      .catch(error => {
        dispatch(setRestorePasswordFetchStatus(RequestStatus.status.ERROR))
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during setting password!');
      });
  }
}

export function setRestorePasswordFetchStatus(payload) {
  return {
    type: FETCH_RESTORE_PASSWORD_STATUS,
    payload
  }
}
