export const SET_FULL_ORGANIZATION_LIST = 'SET_FULL_ORGANIZATION_LIST'
export const SET_FULL_ATA_TYPE_LIST = 'SET_FULL_ATA_TYPE_LIST'
export const SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST = 'SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST'
export const SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS = 'SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS'
export const SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS = 'SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS'
export const SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST = 'SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST'
export const SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS = 'SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS'
export const SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS = 'SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS'
export const SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST = 'SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST'
export const SET_FULL_PBX_LIST = 'SET_FULL_PBX_LIST'
export const SET_FULL_PBX_LIST_FETCH_STATUS = 'SET_FULL_PBX_LIST_FETCH_STATUS'
export const SET_FULL_ATA_BASE_TEMPLATE_LIST = 'SET_FULL_ATA_BASE_TEMPLATE_LIST'
export const SET_FULL_DID_LIST = 'SET_FULL_DID_LIST'
export const SET_FULL_DID_LIST_FETCH_STATUS = 'SET_FULL_DID_LIST_FETCH_STATUS'
export const SET_FULL_ATA_LIST = 'SET_FULL_ATA_LIST'
export const SET_FULL_PROFILE_LIST = 'SET_FULL_PROFILE_LIST'
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY'

