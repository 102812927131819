export const SET_CISCO_ATA_BASE_TEMPLATES_LIST = 'SET_CISCO_ATA_BASE_TEMPLATES_LIST'
export const FETCH_CISCO_ATA_BASE_TEMPLATES_LIST_STATUS = 'FETCH_CISCO_ATA_BASE_TEMPLATES_LIST_STATUS'

export const SET_CISCO_ATA_BASE_TEMPLATES_DETAILS = 'SET_CISCO_ATA_BASE_TEMPLATES_DETAILS'
export const FETCH_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'FETCH_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS'

export const DELETE_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'DELETE_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS'
export const SET_CISCO_ATA_BASE_TEMPLATES_ROUTE_PARAMS = 'SET_CISCO_ATA_BASE_TEMPLATES_ROUTE_PARAMS'

export const POST_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'POST_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS'
export const PATCH_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'PATCH_CISCO_ATA_BASE_TEMPLATES_DETAILS_STATUS'

export const PATCH_CISCO_BASE_CONFIG_DETAILS_STATUS = 'PATCH_CISCO_BASE_CONFIG_DETAILS_STATUS'
