import {
  SET_FULL_ATA_BASE_TEMPLATE_LIST,
  SET_FULL_ATA_LIST,
  SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST,
  SET_FULL_ATA_TYPE_LIST, SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST,
  SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST,
  SET_FULL_DID_LIST,
  SET_FULL_DID_LIST_FETCH_STATUS,
  SET_FULL_ORGANIZATION_LIST,
  SET_FULL_PBX_LIST,
  SET_FULL_PROFILE_LIST,
  SET_SELECTED_COMPANY
} from "./sharedData.actionTypes";

const initialState = {
  fullDIDListFetchStatus: null,
  fullOrganizationList: [],
  fullATATypeList: [],
  fullATAOrganizationTemplateList: [],
  fullCiscoATAOrganizationTemplateList: [],
  fullPBXList: [],
  fullATABaseTemplateList: [],
  fullCiscoATABaseTemplateList: [],
  fullATAList: [],
  fullDIDList: [],
  fullProfileList: [],
  selectedCompany: null
};

function sharedDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FULL_ORGANIZATION_LIST:
      return {...state, fullOrganizationList: action.payload}
    case SET_FULL_ATA_TYPE_LIST:
      return {...state, fullATATypeList: action.payload}
    case SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST:
      return {...state, fullATAOrganizationTemplateList: action.payload}
    case SET_FULL_CISCO_ATA_ORGANIZATION_TEMPLATE_LIST:
      return {...state, fullCiscoATAOrganizationTemplateList: action.payload}
    case SET_FULL_CISCO_ATA_BASE_TEMPLATE_LIST:
      return {...state, fullCiscoATABaseTemplateList: action.payload}
    case SET_FULL_PBX_LIST:
      return {...state, fullPBXList: action.payload}
    case SET_FULL_DID_LIST:
      return {...state, fullDIDList: action.payload}
    case SET_FULL_DID_LIST_FETCH_STATUS:
      return {...state, fullDIDListFetchStatus: action.payload}
    case SET_FULL_ATA_LIST:
      return {...state, fullATAList: action.payload}
    case SET_FULL_ATA_BASE_TEMPLATE_LIST:
      return {...state, fullATABaseTemplateList: action.payload}
    case SET_FULL_PROFILE_LIST:
      return {...state, fullProfileList: action.payload}
    case SET_SELECTED_COMPANY:
      return {...state, selectedCompany: action.payload}
  }
  return state;
}

export default sharedDataReducer;
