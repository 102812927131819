import { connect } from 'react-redux';
import Loader from "./Loader";

const mapStateToProps = (state, otherProps) => {
  return {
    showLoader: state.loaderReducer.requestCount >0
  };
};
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
