export const SET_USERS_LIST = 'SET_USERS_LIST'
export const FETCH_USERS_LIST_STATUS = 'FETCH_USERS_LIST_STATUS'

export const SET_USERS_DETAILS = 'SET_USERS_DETAILS'
export const FETCH_USERS_DETAILS_STATUS = 'FETCH_USERS_DETAILS_STATUS'

export const DELETE_USERS_DETAILS_STATUS = 'DELETE_USERS_DETAILS_STATUS'
export const SET_USERS_ROUTE_PARAMS = 'SET_USERS_ROUTE_PARAMS'

export const POST_USERS_DETAILS_STATUS = 'POST_USERS_DETAILS_STATUS'
export const PATCH_USERS_DETAILS_STATUS = 'PATCH_USERS_DETAILS_STATUS'

export const PATCH_CHANGE_PASSWORD_STATUS = 'PATCH_CHANGE_PASSWORD_STATUS'

export const ADD_ROLE_STATUS = 'ADD_ROLE_STATUS'
