import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const Loader = (props) => {
  return (
    <Backdrop className={'classes.backdrop'} style={{zIndex: 1000000000}} open={props.showLoader}>
      <CircularProgress color="primary" style={{zIndex:3}}/>
    </Backdrop>
  )
}

export default React.memo(Loader);
Loader.whyDidYouRender = true
