export const SET_ATA_TYPES_LIST = 'SET_ATA_TYPES_LIST'
export const FETCH_ATA_TYPES_LIST_STATUS = 'FETCH_ATA_TYPES_LIST_STATUS'

export const SET_ATA_TYPES_DETAILS = 'SET_ATA_TYPES_DETAILS'
export const FETCH_ATA_TYPES_DETAILS_STATUS = 'FETCH_ATA_TYPES_DETAILS_STATUS'

export const DELETE_ATA_TYPES_DETAILS_STATUS = 'DELETE_ATA_TYPES_DETAILS_STATUS'
export const SET_ATA_TYPES_ROUTE_PARAMS = 'SET_ATA_TYPES_ROUTE_PARAMS'

export const POST_ATA_TYPES_DETAILS_STATUS = 'POST_ATA_TYPES_DETAILS_STATUS'
export const PATCH_ATA_TYPES_DETAILS_STATUS = 'PATCH_ATA_TYPES_DETAILS_STATUS'
