import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import whyDidYouRender from "@welldone-software/why-did-you-render";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
  //   trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://8d3b76f385e74312b29519b0ad55a00b@o494649.ingest.sentry.io/5584096",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
