import axios from 'axios';
import SnackbarUtils from "../../utils/SnackbarUtils";
import {
  FETCH_GDMS_UPDATE_STATUS
} from "./GDMS.actionTypes";
import { RequestStatus } from "../../utils/RequestStatus";

export const updateStatus = () => {
  return function (dispatch, getState) {
    dispatch(setUpdateStatusStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/gdms/updateStatus`;
    axios
      .get(url)
      .then(response => {
        SnackbarUtils.success('Statuses have been updated');
        dispatch(setUpdateStatusStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setUpdateStatusStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(error && error.response && error.response.data && error.response.data.message || 'Error during update status');
      });
  }
}

export function setUpdateStatusStatus(payload) {
  return {
    type: FETCH_GDMS_UPDATE_STATUS,
    payload
  }
}
