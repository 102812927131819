import {
  DELETE_DIDS_DETAILS_STATUS,
  FETCH_DIDS_DETAILS_STATUS,
  FETCH_DIDS_LIST_STATUS, SET_DID_BULK,
  SET_DIDS_DETAILS,
  SET_DIDS_LIST,
  SET_DIDS_ROUTE_PARAMS
} from "./dids.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'did.did',
  q: ''
}

const initialState = {
  didList: [],
  didListFetchStatus: null,
  didDetails: null,
  didDetailsFetchStatus: null,
  didDetailsDeleteStatus: null,
  routeParams: initialRouteParams,
  didBulk: null
};

function didsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DIDS_LIST:
      return {...state, didList: action.payload}
    case FETCH_DIDS_LIST_STATUS:
      return {...state, didListFetchStatus: action.payload}
    case SET_DIDS_DETAILS:
      return {...state, didDetails: action.payload}
    case FETCH_DIDS_DETAILS_STATUS:
      return {...state, didDetailsFetchStatus: action.payload}
    case DELETE_DIDS_DETAILS_STATUS:
      return {...state, didDetailsDeleteStatus: action.payload}
    case SET_DID_BULK:
      return {...state, didBulk: action.payload}
    case SET_DIDS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default didsReducer;
