export const SET_CISCO_ATAS_LIST = 'SET_CISCO_ATAS_LIST'
export const FETCH_CISCO_ATAS_LIST_STATUS = 'FETCH_CISCO_ATAS_LIST_STATUS'
export const SET_CISCO_ATAS_DETAILS = 'SET_CISCO_ATAS_DETAILS'
export const SET_CISCO_ATAS_DETAILS_LOCAL_UPDATE_STATUS = 'SET_CISCO_ATAS_DETAILS_LOCAL_UPDATE_STATUS'
export const FETCH_CISCO_ATAS_DETAILS_STATUS = 'FETCH_CISCO_ATAS_DETAILS_STATUS'

export const DELETE_CISCO_ATAS_DETAILS_STATUS = 'DELETE_CISCO_ATAS_DETAILS_STATUS'
export const SET_CISCO_ATAS_ROUTE_PARAMS = 'SET_CISCO_ATAS_ROUTE_PARAMS'

export const POST_CISCO_ATAS_DETAILS_STATUS = 'POST_CISCO_ATAS_DETAILS_STATUS'
export const PATCH_CISCO_ATAS_DETAILS_STATUS = 'PATCH_CISCO_ATAS_DETAILS_STATUS'

export const PATCH_CISCO_ATA_OVERRIDES_STATUS = 'PATCH_CISCO_ATA_OVERRIDES_STATUS'
