import React from "react";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import VisibilityGuard from "../VisibilityGuard";

interface Props {
  nested?: boolean,
  className?: string,
  static?: boolean,
  label: string,
  path?: string
  allowedRoles?: any;
}

const LinkContainer = styled(Box)({
  '&.active': {
    fontWeight: 'bold'
  }
})

const linkItemStyles = {
  color: '#6c6c6c',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}
const LinkItem = styled(Link)(linkItemStyles)
const RawLinkItem = styled('a')(linkItemStyles)

const DrawerMenuLink = (props: Props) => {
  return (
    <LinkContainer pl={props.nested ? 2 : 0}
                   py={props.nested ? 0.5 : 0}
                   className={props.className}>
      {props.static || !props.path
        ? <RawLinkItem>{props.label}</RawLinkItem>
        : <VisibilityGuard allowedRoles={props?.allowedRoles || []}>
          <LinkItem to={props.path}>{props.label}</LinkItem>
        </VisibilityGuard>}
    </LinkContainer>
  )
}

export default React.memo(DrawerMenuLink);
DrawerMenuLink.whyDidYouRender = true
