import React, { useEffect, useState } from "react";


const VisibilityGuard = (props) => {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (props.user)
      checkVisibility();
  }, [props.user])

  useEffect(() => {
    checkVisibility();
  })

  const checkVisibility = () => {
    if (!props.user) return;
    if (props.allowedRoles && props.allowedRoles.length) {
      setShowChildren(props.allowedRoles.includes(props.user.role))
    } else {
      setShowChildren(true)
    }
  }

  return showChildren ? props.children : (props.showEmpty ? <>&nbsp;</> : null)
}


function areEqual(p, n) {
  const a1 = p.allowedRoles || [];
  const a2 = n.allowedRoles || [];
  if (p.renderAlways)
    return false

  if (p.showEmpty === n.showEmpty &&
    (!a1.hasOwnProperty('allowedRoles') && (!a2.hasOwnProperty('allowedRoles'))) || (!a1.allowedRoles.length && !a2.allowedRoles.length)) return true
  const equal = p.showEmpty === n.showEmpty && a1.length === a2.length && a1.every((value, index) => value === a2[index]);
  return equal;
}

export default React.memo(VisibilityGuard);
VisibilityGuard.whyDidYouRender = true
