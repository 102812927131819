import { applyMiddleware, combineReducers, createStore } from 'redux';
import utilsReducer from "./containers/Utils/utils.reducer";
import authReducer from "./containers/Auth/auth.reducers";
import organizationsReducer from "./containers/Organizations/organizations.reducers";
import loaderReducer from "./containers/Loader/loader.reducer";
import thunkMiddlware from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension/index";
import logger from "redux-logger";
import sharedDataReducer from "./containers/SharedData/sharedData.reducers";
import usersReducer from "./containers/Users/users.reducers";
import pbxReducer from "./containers/PBXs/pbx.reducers";
import ataTypesReducer from "./containers/ATATypes/ataTypes.reducers";
import apiCredentialsReducer from "./containers/APICredentials/apiCredentials.reducers";
import ataBaseTemplatesReducer from "./containers/ATABaseTemplates/ataBaseTemplates.reducers";
import atasReducer from "./containers/ATAs/atas.reducers";
import ataOrgTemplatesReducer from "./containers/ATAOrgTemplates/ataOrgTemplates.reducers";
import didsReducer from "./containers/DIDs/dids.reducers";
import ataPortsReducer from "./containers/ATAPorts/ataPorts.reducers";
import statsReducer from "./containers/Stats/stats.reducers";
import billingReducer from "./containers/Billings/billing.reducers";
import paymentMethodReducer from "./containers/PaymentMethods/paymentMethods.reducers";
import paymentHistoryReducer from "./containers/PaymentHistory/paymentHistory.reducers";
import ciscoAtasReducer from "./containers/CiscoATAs/ciscoAtas.reducers"
import ciscoAtaOrgTemplatesReducer from "./containers/CiscoATAOrgTemplates/ciscoAtaOrgTemplates.reducers";
import ciscoAtaBaseTemplatesReducer from "./containers/CiscoATABaseTemplates/ciscoAtaBaseTemplates.reducers";
import gdmsReducer from "./containers/GDMS/GDMS.reducers";
import revioReducer from "./containers/Revio/Revio.reducers";

const reducer = combineReducers({
  utilsReducer,
  authReducer,
  organizationsReducer,
  loaderReducer,
  sharedDataReducer,
  usersReducer,
  pbxReducer,
  ataTypesReducer,
  ataBaseTemplatesReducer,
  apiCredentialsReducer,
  atasReducer,
  ciscoAtasReducer,
  ataOrgTemplatesReducer,
  ciscoAtaOrgTemplatesReducer,
  ciscoAtaBaseTemplatesReducer,
  didsReducer,
  ataPortsReducer,
  statsReducer,
  billingReducer,
  paymentMethodReducer,
  paymentHistoryReducer,
  gdmsReducer,
  revioReducer
})

const getMiddleware = () => {
  let middleware: any[] = [thunkMiddlware];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }
  return middleware
};
export const store = createStore(reducer, composeWithDevTools(applyMiddleware(...getMiddleware())));
