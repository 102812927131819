import { SET_MODAL_CONTENT, SHOW_MODAL } from "./utils.actionTypes";

const initialState = {
  showModal: false,
  modalContent: null
};

function utilsReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {...state, showModal: action.payload}
    case SET_MODAL_CONTENT:
      return {...state, modalContent: action.payload}
  }
  return state;
}

export default utilsReducer;
