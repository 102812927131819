export const SET_PBX_LIST = 'SET_PBX_LIST'
export const FETCH_PBX_LIST_STATUS = 'FETCH_PBX_LIST_STATUS'

export const SET_PBX_DETAILS = 'SET_PBX_DETAILS'
export const FETCH_PBX_DETAILS_STATUS = 'FETCH_PBX_DETAILS_STATUS'

export const DELETE_PBX_DETAILS_STATUS = 'DELETE_PBX_DETAILS_STATUS'
export const SET_PBX_ROUTE_PARAMS = 'SET_PBX_ROUTE_PARAMS'

export const POST_PBX_DETAILS_STATUS = 'POST_PBX_DETAILS_STATUS'
export const PATCH_PBX_DETAILS_STATUS = 'PATCH_PBX_DETAILS_STATUS'
