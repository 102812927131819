import {
  DELETE_ATA_ORG_TEMPLATES_DETAILS_STATUS,
  FETCH_ATA_ORG_TEMPLATES_DETAILS_STATUS,
  FETCH_ATA_ORG_TEMPLATES_LIST_STATUS,
  SET_ATA_ORG_TEMPLATES_DETAILS,
  SET_ATA_ORG_TEMPLATES_LIST,
  SET_ATA_ORG_TEMPLATES_ROUTE_PARAMS
} from "./ataOrgTemplates.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'ata_org_template.name',
  q:''
}

const initialState = {
  ataOrgTemplateList: [],
  ataOrgTemplateListFetchStatus: null,
  ataOrgTemplateDetails: null,
  ataOrgTemplateDetailsFetchStatus: null,
  ataOrgTemplateDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function ataOrgTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ATA_ORG_TEMPLATES_LIST:
      return {...state, ataOrgTemplateList: action.payload}
    case FETCH_ATA_ORG_TEMPLATES_LIST_STATUS:
      return {...state, ataOrgTemplateListFetchStatus: action.payload}
    case SET_ATA_ORG_TEMPLATES_DETAILS:
      return {...state, ataOrgTemplateDetails: action.payload}
    case FETCH_ATA_ORG_TEMPLATES_DETAILS_STATUS:
      return {...state, ataOrgTemplateDetailsFetchStatus: action.payload}
    case DELETE_ATA_ORG_TEMPLATES_DETAILS_STATUS:
      return {...state, ataOrgTemplateDetailsDeleteStatus: action.payload}
    case SET_ATA_ORG_TEMPLATES_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ataOrgTemplatesReducer;
