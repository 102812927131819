import { connect } from 'react-redux';
import Layout from "./Layout";
import { changeModalState } from "../Utils/utils.actions";
import { updateStatus } from "../GDMS/GDMS.actions";

const mapStateToProps = (state, otherProps) => {
  return {
    showModal: state.utilsReducer.showModal,
    modalContent: state.utilsReducer.modalContent,
  };
};

const mapDispatchToProps = dispatch => ({
  changeModalState: (value) => {
    dispatch(changeModalState(value));
  },
  updateStatus: () => {
    dispatch(updateStatus());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
