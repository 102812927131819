import { connect } from 'react-redux';
import SubHeader from "./SubHeader";
import { setSelectedCompany } from "../../containers/SharedData/sharedData.actions";

const mapStateToProps = (state, otherProps) => {
  return {
    user: state.authReducer.user,
    selectedCompany: state.sharedDataReducer.selectedCompany
  };
};
const mapDispatchToProps = dispatch => ({
  setSelectedCompany: (data) => {
    dispatch(setSelectedCompany(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
