import { connect } from 'react-redux';
import VisibilityGuard from "./VisibilityGuard";

const mapStateToProps = (state, otherProps) => {
  return {
    user: state.authReducer.user,
  };
};
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(VisibilityGuard);
