export const SET_ORGANIZATIONS_LIST = 'SET_ORGANIZATIONS_LIST'
export const FETCH_ORGANIZATIONS_LIST_STATUS = 'FETCH_ORGANIZATIONS_LIST_STATUS'

export const SET_ORGANIZATIONS_DETAILS = 'SET_ORGANIZATIONS_DETAILS'
export const FETCH_ORGANIZATIONS_DETAILS_STATUS = 'FETCH_ORGANIZATIONS_DETAILS_STATUS'

export const DELETE_ORGANIZATIONS_DETAILS_STATUS = 'DELETE_ORGANIZATIONS_DETAILS_STATUS'
export const SET_ORGANIZATIONS_ROUTE_PARAMS = 'SET_ORGANIZATIONS_ROUTE_PARAMS'

export const POST_ORGANIZATIONS_DETAILS_STATUS = 'POST_ORGANIZATIONS_DETAILS_STATUS'
export const PATCH_ORGANIZATIONS_DETAILS_STATUS = 'PATCH_ORGANIZATIONS_DETAILS_STATUS'
