export const ROLECONSTANTS = {
  superadmin: 'superadmin',
  admin: 'admin',
  billingadmin: 'billingadmin',
  user: 'user',
  viewer: 'viewer',
}

let rolesArray: Array<any> = [];
Object.keys(ROLECONSTANTS).forEach(key => {
  rolesArray.push({id: key, role: ROLECONSTANTS[key]});
})
export const RolesArray = rolesArray;
