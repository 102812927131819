import {
  FETCH_FORGOT_PASSWORD_STATUS,
  FETCH_USER_DATA_STATUS,
  SET_USER_DATA,
  TOKEN_CHECKED
} from "./auth.actionTypes";

const initialState = {
  user: null,
  tokenChecked: null,
  userDataFetchStatus: null,
  forgotPasswordFetchStatus: null
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {...state, user: action.payload}
    case TOKEN_CHECKED:
      return {...state, tokenChecked: action.payload}
    case FETCH_USER_DATA_STATUS:
      return {...state, userDataFetchStatus: action.payload}
  }
  return state;
}

export default authReducer;
