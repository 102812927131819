import {
  DELETE_CISCO_ATAS_DETAILS_STATUS,
  FETCH_CISCO_ATAS_DETAILS_STATUS,
  FETCH_CISCO_ATAS_LIST_STATUS,
  SET_CISCO_ATAS_DETAILS,
  SET_CISCO_ATAS_DETAILS_LOCAL_UPDATE_STATUS,
  SET_CISCO_ATAS_LIST,
  SET_CISCO_ATAS_ROUTE_PARAMS
} from "./ciscoAtas.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'organization.name',
  q: ''
}

const initialState = {
  ciscoAtaList: [],
  ciscoAtaListFetchStatus: null,
  ciscoAtaDetails: null,
  ciscoAtaDetailsFetchStatus: null,
  ciscoAtaDetailsDeleteStatus: null,
  ciscoAtaDetailsLocalUpdateStatus: null,
  routeParams: initialRouteParams
};

function ciscoAtasReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CISCO_ATAS_LIST:
      return {...state, ciscoAtaList: action.payload}
    case FETCH_CISCO_ATAS_LIST_STATUS:
      return {...state, ciscoAtaListFetchStatus: action.payload}
    case SET_CISCO_ATAS_DETAILS:
      return {...state, ciscoAtaDetails: action.payload}
    case FETCH_CISCO_ATAS_DETAILS_STATUS:
      return {...state, ciscoAtaDetailsFetchStatus: action.payload}
    case DELETE_CISCO_ATAS_DETAILS_STATUS:
      return {...state, ciscoAtaDetailsDeleteStatus: action.payload}
    case SET_CISCO_ATAS_DETAILS_LOCAL_UPDATE_STATUS:
      return {...state, ciscoAtaDetailsLocalUpdateStatus: action.payload}
    case SET_CISCO_ATAS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default ciscoAtasReducer;
