import {
  DELETE_USERS_DETAILS_STATUS,
  FETCH_USERS_DETAILS_STATUS,
  FETCH_USERS_LIST_STATUS,
  SET_USERS_DETAILS,
  SET_USERS_LIST,
  SET_USERS_ROUTE_PARAMS
} from "./users.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'user.lastname',
  q: ''
}

const initialState = {
  userList: [],
  userListFetchStatus: null,
  userDetails: null,
  userDetailsFetchStatus: null,
  userDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS_LIST:
      return {...state, userList: action.payload}
    case FETCH_USERS_LIST_STATUS:
      return {...state, userListFetchStatus: action.payload}
    case SET_USERS_DETAILS:
      return {...state, userDetails: action.payload}
    case FETCH_USERS_DETAILS_STATUS:
      return {...state, userDetailsFetchStatus: action.payload}
    case DELETE_USERS_DETAILS_STATUS:
      return {...state, userDetailsDeleteStatus: action.payload}
    case SET_USERS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default usersReducer;
