import {
  FETCH_PAYMENT_HISTORY_LIST_STATUS,
  SET_PAYMENT_HISTORY_LIST,
  SET_PAYMENT_HISTORY_ROUTE_PARAMS, SET_PAYMENT_HISTORY_SUM_DATA
} from "./paymentHistory.actionTypes";

const initialRouteParams = {
  order: 'payment.amount',
  page: 1,
  limit: 10,
}

const initialState = {
  paymentHistoryList: [],
  paymentHistoryListFetchStatus: null,
  routeParams: initialRouteParams,
  sum: 0
};

function paymentHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_HISTORY_LIST:
      return {...state, paymentHistoryList: action.payload}
    case FETCH_PAYMENT_HISTORY_LIST_STATUS:
      return {...state, paymentHistoryListFetchStatus: action.payload}
    case SET_PAYMENT_HISTORY_SUM_DATA:
      return {...state, sum: action.payload}
    case SET_PAYMENT_HISTORY_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default paymentHistoryReducer;
