import {
  FETCH_PAYMENT_METHOD_LIST_STATUS,
  SET_PAYMENT_METHOD_LIST, SET_PAYMENT_METHOD_ROUTE_PARAMS
  // DELETE_PBX_DETAILS_STATUS,
  // FETCH_PBX_DETAILS_STATUS,
  // FETCH_PBX_LIST_STATUS,
  // SET_PBX_DETAILS,
  // SET_PBX_LIST,
  // SET_PBX_ROUTE_PARAMS
} from "./paymentMethods.actionTypes";

const initialRouteParams = {
  page: 1,
  limit: 10,
  order: ''
}

const initialState = {
  paymentMethodList: [],
  paymentMethodListFetchStatus: null,
  // paymentMethodDetails: null,
  // paymentMethodDetailsFetchStatus: null,
  // paymentMethodDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function paymentMethodReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_METHOD_LIST:
      return {...state, paymentMethodList: action.payload}
    case FETCH_PAYMENT_METHOD_LIST_STATUS:
      return {...state, paymentMethodListFetchStatus: action.payload}
    // case SET_PBX_DETAILS:
    //   return {...state, pbxDetails: action.payload}
    // case FETCH_PBX_DETAILS_STATUS:
    //   return {...state, pbxDetailsFetchStatus: action.payload}
    // case DELETE_PBX_DETAILS_STATUS:
    //   return {...state, pbxDetailsDeleteStatus: action.payload}
    case SET_PAYMENT_METHOD_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default paymentMethodReducer;
