import {
  FETCH_BILLING_DATA_STATUS,
  FETCH_FILES_DATA_STATUS,
  FETCH_ORGANIZATION_BALANCE_DATA_STATUS,
  SET_BILLING_DATA,
  SET_BILLING_ROUTE_PARAMS,
  SET_FILES_DATA,
  SET_ORGANIZATION_BALANCE_DATA,
  UPLOAD_ORG_FILE_DATA_STATUS,
} from "./billing.actionTypes";

const initialRouteParams = {
  page: 1,
  limit: 10,
  from: '',
  to: ''
}

const initialState = {
  organizationBalanceData: null,
  organizationBalanceDataFetchStatus: null,
  billingData: [],
  filesData: [],
  billingDataFetchStatus: null,
  filesDataFetchStatus: null,
  uploadOrgFileStatus: null,
  routeParams: initialRouteParams,
};

function billingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION_BALANCE_DATA:
      return {...state, organizationBalanceData: action.payload}
    case FETCH_ORGANIZATION_BALANCE_DATA_STATUS:
      return {...state, organizationBalanceDataFetchStatus: action.payload}
    case SET_BILLING_DATA:
      return {...state, billingData: action.payload}
    case FETCH_BILLING_DATA_STATUS:
      return {...state, billingDataFetchStatus: action.payload}
    case SET_FILES_DATA:
      return {...state, filesData: action.payload}
    case FETCH_FILES_DATA_STATUS:
      return {...state, filesDataFetchStatus: action.payload}
    case SET_BILLING_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
    case UPLOAD_ORG_FILE_DATA_STATUS:
      return {...state, uploadOrgFileStatus: action.payload}
  }
  return state;
}

export default billingReducer;
