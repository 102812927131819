import {
  DELETE_PBX_DETAILS_STATUS,
  FETCH_PBX_DETAILS_STATUS,
  FETCH_PBX_LIST_STATUS,
  SET_PBX_DETAILS,
  SET_PBX_LIST,
  SET_PBX_ROUTE_PARAMS
} from "./pbx.actionTypes";

const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'pbx.description'
}

const initialState = {
  pbxList: [],
  pbxListFetchStatus: null,
  pbxDetails: null,
  pbxDetailsFetchStatus: null,
  pbxDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function pbxReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PBX_LIST:
      return {...state, pbxList: action.payload}
    case FETCH_PBX_LIST_STATUS:
      return {...state, pbxListFetchStatus: action.payload}
    case SET_PBX_DETAILS:
      return {...state, pbxDetails: action.payload}
    case FETCH_PBX_DETAILS_STATUS:
      return {...state, pbxDetailsFetchStatus: action.payload}
    case DELETE_PBX_DETAILS_STATUS:
      return {...state, pbxDetailsDeleteStatus: action.payload}
    case SET_PBX_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default pbxReducer;
