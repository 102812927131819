import {
  DELETE_ATAS_DETAILS_STATUS,
  FETCH_ATAS_DETAILS_STATUS,
  FETCH_ATAS_LIST_STATUS, SET_ATA_BULK,
  SET_ATAS_DETAILS,
  SET_ATAS_DETAILS_LOCAL_UPDATE_STATUS,
  SET_ATAS_LIST,
  SET_ATAS_ROUTE_PARAMS
} from "./atas.actionTypes";


export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'organization.name',
  q:''
}

const initialState = {
  ataList: [],
  ataListFetchStatus: null,
  ataDetails: null,
  ataDetailsFetchStatus: null,
  ataDetailsDeleteStatus: null,
  ataDetailsLocalUpdateStatus: null,
  ataBulk: null,
  routeParams: initialRouteParams
};

function atasReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ATAS_LIST:
      return {...state, ataList: action.payload}
    case FETCH_ATAS_LIST_STATUS:
      return {...state, ataListFetchStatus: action.payload}
    case SET_ATAS_DETAILS:
      return {...state, ataDetails: action.payload}
    case FETCH_ATAS_DETAILS_STATUS:
      return {...state, ataDetailsFetchStatus: action.payload}
    case DELETE_ATAS_DETAILS_STATUS:
      return {...state, ataDetailsDeleteStatus: action.payload}
    case SET_ATAS_DETAILS_LOCAL_UPDATE_STATUS:
      return {...state, ataDetailsLocalUpdateStatus: action.payload}
    case SET_ATA_BULK:
      return {...state, ataBulk: action.payload}
    case SET_ATAS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default atasReducer;
