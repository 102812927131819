import {
  DELETE_API_CREDENTIALS_DETAILS_STATUS,
  FETCH_API_CREDENTIALS_DETAILS_STATUS, FETCH_API_CREDENTIALS_LIST_STATUS,
  SET_API_CREDENTIALS_DETAILS,
  SET_API_CREDENTIALS_LIST,
  SET_API_CREDENTIALS_ROUTE_PARAMS
} from "./apiCredentials.actionTypes";

export const initialRouteParams = {
  page: 1,
  limit: 10,
  order: 'organization.name',
  q:''
  // orgId: '5'
}

const initialState = {
  apiCredentialList: [],
  apiCredentialListFetchStatus: null,
  apiCredentialDetails: null,
  apiCredentialDetailsFetchStatus: null,
  apiCredentialDetailsDeleteStatus: null,
  routeParams: initialRouteParams
};

function apiCredentialsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_API_CREDENTIALS_LIST:
      return {...state, apiCredentialList: action.payload}
    case FETCH_API_CREDENTIALS_LIST_STATUS:
      return {...state, apiCredentialListFetchStatus: action.payload}
    case SET_API_CREDENTIALS_DETAILS:
      return {...state, apiCredentialDetails: action.payload}
    case FETCH_API_CREDENTIALS_DETAILS_STATUS:
      return {...state, apiCredentialDetailsFetchStatus: action.payload}
    case DELETE_API_CREDENTIALS_DETAILS_STATUS:
      return {...state, apiCredentialDetailsDeleteStatus: action.payload}
    case SET_API_CREDENTIALS_ROUTE_PARAMS:
      return {...state, routeParams: {...state.routeParams, ...action.payload}}
  }
  return state;
}

export default apiCredentialsReducer;
