export const SET_ATAS_LIST = 'SET_ATAS_LIST'
export const FETCH_ATAS_LIST_STATUS = 'FETCH_ATAS_LIST_STATUS'

export const SET_ATAS_DETAILS = 'SET_ATAS_DETAILS'
export const SET_ATAS_DETAILS_LOCAL_UPDATE_STATUS = 'SET_ATAS_DETAILS_LOCAL_UPDATE_STATUS'
export const FETCH_ATAS_DETAILS_STATUS = 'FETCH_ATAS_DETAILS_STATUS'

export const DELETE_ATAS_DETAILS_STATUS = 'DELETE_ATAS_DETAILS_STATUS'
export const REBOOT_ATA_STATUS = 'REBOOT_ATA_STATUS'
export const FACTORY_RESET_ATA_STATUS = 'FACTORY_RESET_ATA_STATUS'
export const SET_ATAS_ROUTE_PARAMS = 'SET_ATAS_ROUTE_PARAMS'

export const POST_ATAS_DETAILS_STATUS = 'POST_ATAS_DETAILS_STATUS'
export const PATCH_ATAS_DETAILS_STATUS = 'PATCH_ATAS_DETAILS_STATUS'

export const PATCH_ATA_OVERRIDES_STATUS = 'PATCH_ATA_OVERRIDES_STATUS'

export const POST_ATAS_BULK_DETAILS_STATUS = 'POST_ATAS_BULK_DETAILS_STATUS'
export const SET_ATA_BULK = 'SET_ATA_BULK'
