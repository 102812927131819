export const SET_PAYMENT_METHOD_LIST = 'SET_PAYMENT_METHOD_LIST'
export const FETCH_PAYMENT_METHOD_LIST_STATUS = 'FETCH_PAYMENT_METHOD_LIST_STATUS'

// export const SET_PBX_DETAILS = 'SET_PBX_DETAILS'
// export const FETCH_PBX_DETAILS_STATUS = 'FETCH_PBX_DETAILS_STATUS'
//
export const POST_PAYMENT_METHOD_STATUS = 'POST_PAYMENT_METHOD_STATUS'

export const DELETE_PAYMENT_METHOD_DETAILS_STATUS = 'DELETE_PAYMENT_METHOD_DETAILS_STATUS'
export const SET_PAYMENT_METHOD_ROUTE_PARAMS = 'SET_PAYMENT_METHOD_ROUTE_PARAMS'

export const SET_ADD_FUND_FETCH_STATUS = 'SET_ADD_FUND_FETCH_STATUS'
//
// export const POST_PBX_DETAILS_STATUS = 'POST_PBX_DETAILS_STATUS'
// export const PATCH_PBX_DETAILS_STATUS = 'PATCH_PBX_DETAILS_STATUS'
